.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: var(--dialog-bg);
  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2.5rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--link);
    font-weight: 300;
  }
  .wrapper {
    @media only screen and (max-width: 1120px) {
      margin-left: 0;
    }

    margin-left: 240px;
    transition: margin-left 0.15s;
    max-width: var(--max-container-width);
    height: calc(100% - 180px);
    width: calc(100% - 40px);
    margin-top: 90px;
    padding: 40px;
    // background: var(--field-bg);
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;
      padding: 0;

      .title {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 15px 0;
        letter-spacing: -0.3px;
        color: #fefefe;
        cursor: pointer;
        position: relative;
        transition: color 0.2s;
      }
    }

    .body {
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      padding: 15px 0;
      gap: 20px;
      //   border: 1px solid saddlebrown;
      align-content: flex-start;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        // border-right: 6px transparent solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .studentItem {
        width: calc(25% - 15px);
        @media only screen and (max-width: 1600px) {
          width: calc(33.3% - 15px);
        }
        @media only screen and (max-width: 1260px) {
          width: calc(50% - 15px);
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
        }

        //   .studentItemWrapper {
        //     height: 118px;
        //     border: 1px dashed var(--field-bg);
        //     background: var(--field-bg);
        //     border-radius: 12px;
        //     display: flex;
        //     flex: 1;
        //     flex-direction: row;
        //     position: relative;

        //     .left {
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       width: 35px;
        //       label {
        //         position: absolute;
        //         width: 100%;
        //         height: 100%;
        //         left: 0;
        //         top: 0;
        //         cursor: pointer;
        //         &:before {
        //           content: "";
        //           position: absolute;
        //           height: 20px;
        //           width: 20px;
        //           top: calc(50% - 10px);
        //           left: 10px;
        //           border-radius: 50%;
        //           background: var(--dialog-bg);
        //           border: 1px solid var(--secondary-bg);
        //           outline: none;
        //         }

        //         &:after {
        //           content: "";
        //           position: absolute;
        //           height: 16px;
        //           width: 16px;
        //           top: calc(50% - 7px);
        //           left: 13px;
        //           border-radius: 50%;
        //           background: var(--dialog-bg);

        //           outline: none;
        //         }
        //       }

        //       input[type="checkbox"] {
        //         &:checked + label::after {
        //           background: var(--accent-color-1);
        //         }
        //       }
        //     }
        //     .right {
        //       display: flex;
        //       flex-direction: column;
        //       flex: 1;
        //       .row {
        //         margin: 12px;
        //         padding: 0;
        //         display: flex;
        //         flex-wrap: wrap;
        //         gap: 10px;
        //         flex-direction: row;
        //         align-items: center;
        //         img {
        //           width: 56px;
        //           height: 56px;
        //           object-fit: cover;
        //           border-radius: 8px;
        //           display: flex;
        //         }
        //         .details {
        //           display: flex;
        //           flex-direction: column;
        //           flex: 1;
        //           gap: 5px;
        //           .name {
        //             font-size: 1rem;
        //             font-weight: bold;
        //             letter-spacing: -0.3px;
        //             color: #fefefe;
        //           }
        //           .role {
        //             margin: 0;
        //             font-size: 0.875rem;
        //             font-weight: 400;
        //             letter-spacing: 0;
        //             color: var(--text-color-1);
        //           }
        //         }
        //         .timestamp,
        //         .payment {
        //           margin: 0;
        //           font-size: 0.75rem;
        //           font-weight: 400;
        //           letter-spacing: 0;
        //           color: var(--text-color-1);
        //         }
        //         .payment {
        //           margin-left: auto;
        //         }
        //       }
        //     }
        //   }
      }
      // .add {
      //   opacity: 0.5;

      //   cursor: pointer;
      //   transition: opacity 0.2s;
      //   &:hover {
      //     opacity: 0.7;
      //   }
      //   width: calc(25% - 15px);
      //   @media only screen and (max-width: 1600px) {
      //     width: calc(33.3% - 15px);
      //   }
      //   @media only screen and (max-width: 1260px) {
      //     width: calc(50% - 15px);
      //   }
      //   @media only screen and (max-width: 980px) {
      //     width: 100%;
      //   }
      //   .body {
      //     height: 118px;

      //     border: 1px dashed var(--text-color-1);
      //     border-radius: 12px;
      //     display: flex;
      //     flex: 1;

      //     justify-content: center;
      //     align-items: center;
      //     gap: 15px;
      //     .icon {
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       font-size: 5rem;
      //       padding: 15px;
      //       width: 30px;
      //       height: 30px;
      //       color: var(--text-color-1);
      //       border: 3px solid var(--text-color-1);
      //       border-radius: 50%;
      //     }
      //     .label {
      //       color: var(--text-color-1);
      //     }
      //   }
      // }
    }
    .footer {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      margin-top: auto;
      justify-content: space-between;
    }
  }
}
