@import-normalize; /* bring in normalize.css styles */
:root {
  --font-stack: "Sofia Pro", sans-serif;
  --main-bg: #191a23;
  --secondary-bg: #0d1118;
  --third-bg: #13171e;
  --primary: #9d9ca0;
  --secondary: #fefefe;
  --link: #fefefe;
  --text-color-1: #7a8798;
  --accent-color-1: #cc33ff;
  --accent-color-2: #8e34fc;
  --gradient-bg-1: transparent
    linear-gradient(95deg, var(--accent-color-1), var(--accent-color-2) 100%) 0%
    0% no-repeat padding-box;
  // --btn-bg: transparent linear-gradient(95deg, #7f4de5 0%, #e75a57 100%) 0% 0%
  // no-repeat padding-box;

  --dialog-bg: #0d1118;
  --field-bg: #202934;
  --field-bg-focused: #191f26;
  --error-color: #fc5a83;
  --max-container-width: 1520px;
  --success-confirmation: #99e24a;
  --error-confirmation: #fc5b0c;
}

@import url("https://fonts.cdnfonts.com/css/sofia-pro");

body {
  margin: 0;
  height: 100%;
  font-family: "Sofia Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-bg);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: var(--main-bg);
  &::-webkit-scrollbar {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ==========================================================================
    Global overrides
  ========================================================================== */

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
  color: var(--link) !important;
  text-decoration: none;
}

a:-webkit-any-link:active {
  color: var(--accent-color-1) !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
