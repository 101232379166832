.root {
  width: 25%;
  @media only screen and (max-width: 1600px) {
    width: 33.3%;
  }
  @media only screen and (max-width: 1260px) {
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    width: 100%;
  }

  .wrapper {
    margin: 10px;
    height: 200px;
    background-color: var(--secondary-bg);
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    .title {
      margin: 35px 15px 5px 15px;
      font-size: 1rem;
      font-weight: 700;
      color: #f2ffff;
    }
    .subtitle {
      margin: 0 15px;
      font-size: 0.85rem;
      font-weight: 500;
      color: var(--text-color-1);
      padding: 3px 0;
      text-transform: capitalize !important;
    }
    .users {
      margin: 30px 15px 5px 15px;
      .label {
        font-size: 0.6875rem;
        font-weight: 500;
        color: var(--text-color-1);
        padding: 3px 0;
        text-transform: capitalize !important;
      }
      .count {
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
      }
    }
    .actions {
      // display: none;
      margin-top: auto;
      gap: 2px;
      border-radius: 3px;
      .button {
        background: var(--gradient-bg-1);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 10px 0;
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
        cursor: pointer;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        // &:first-child {
        //   border-bottom-left-radius: 15px;
        // }
        // &:last-child {
        //   border-bottom-right-radius: 15px;
        // }
      }
    }
    .questionCount {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -10px;
      right: -10px;
      background: var(--gradient-bg-1);
      padding: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 0.875rem;
      font-weight: 700;
      color: #f2ffff;
    }
  }
}
