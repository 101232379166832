.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
      }
      .contents {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        width: 100%;

        .form {
          display: flex;
          flex-direction: column;
          flex: 1;
          form {
            display: flex;
            flex: 1;
            flex-direction: column;

            margin: 10px;
            gap: 45px;
            @media only screen and (max-width: 960px) {
              gap: 20px;
            }
            .row {
              display: flex;
              gap: 15px;
              flex-wrap: wrap;
              flex-direction: column;
              margin-top: 20px;
              @media only screen and (max-width: 960px) {
                flex-direction: column;
                gap: 20px;
              }
              .title {
                color: var(--text-color-1);
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.0625rem;
                margin-bottom: 5px;
              }
              .fields {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-wrap: wrap;
                .field {
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  //   //   width: 320px;
                  //   height: 65px;
                  @media only screen and (max-width: 1120px) {
                    flex: 1;
                  }
                  @media only screen and (max-width: 960px) {
                    width: 100%;
                    // height: 64px;
                    flex: inherit;
                  }
                }
              }
              .categories {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                .selected {
                  width: calc(20% - 40px);
                  @media only screen and (max-width: 1600px) {
                    width: calc(33.3% - 40px);
                  }
                  @media only screen and (max-width: 1260px) {
                    width: calc(50% - 40px);
                  }
                  @media only screen and (max-width: 980px) {
                    width: 100%;
                  }
                  .body {
                    height: 77px;

                    background: var(--field-bg);
                    border-radius: 12px;
                    display: flex;
                    flex: 1;

                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    .icon {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 3.5rem;
                      padding: 15px;
                      width: 15px;
                      height: 15px;
                      color: var(--text-color-1);
                      border: 2px solid var(--text-color-1);
                      border-radius: 50%;
                    }
                    .label {
                      color: var(--text-color-1);
                    }
                  }
                }

                .add {
                  // opacity: 0.5;

                  cursor: pointer;
                  transition: opacity 0.2s;

                  &:hover {
                    opacity: 0.7;
                  }
                  width: calc(20% - 40px);
                  @media only screen and (max-width: 1600px) {
                    width: calc(33.3% - 40px);
                  }
                  @media only screen and (max-width: 1260px) {
                    width: calc(50% - 40px);
                  }
                  @media only screen and (max-width: 980px) {
                    width: 100%;
                  }
                  .body {
                    height: 77px;

                    border: 1px dashed var(--text-color-1);
                    border-radius: 12px;
                    display: flex;
                    flex: 1;

                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    .icon {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 45px;
                      height: 45px;
                      border: 3px solid var(--text-color-1);
                      border-radius: 50%;
                      position: relative;
                      &:before {
                        content: "";
                        display: block;
                        width: 4px;
                        height: 20px;
                        position: absolute;
                        top: calc(50% - 10px);
                        left: calc(50% - 2px);
                        background: var(--text-color-1);
                      }
                      &:after {
                        content: "";
                        display: block;
                        height: 4px;
                        width: 20px;
                        position: absolute;
                        left: calc(50% - 10px);
                        top: calc(50% - 2px);
                        background: var(--text-color-1);
                      }
                    }
                    .label {
                      color: var(--text-color-1);
                    }
                  }
                }
              }
            }
          }

          .actions {
            display: flex;
            margin: 100px 10px;

            gap: 20px;
            justify-content: flex-end;
            // .delete {
            //   display: flex;
            //   border-radius: 20px;
            //   justify-content: center;
            //   align-items: center;
            //   font-weight: bold;
            //   font-size: 1rem;
            //   letter-spacing: -0.16px;
            //   color: #fff;
            //   border: none;
            //   box-shadow: inset 0 0 2px var(--text-color-1);

            //   gap: 5px;
            //   background: transparent;
            //   cursor: pointer;
            //   &:hover {
            //     background: #262b34;
            //   }
            // }
            // .save {
            //   display: flex;
            //   border-radius: 20px;
            //   justify-content: center;
            //   align-items: center;
            //   font-weight: bold;
            //   font-size: 1rem;
            //   letter-spacing: -0.16px;
            //   color: #fff;
            //   border: none;
            //   box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
            //   gap: 5px;
            //   background: var(--gradient-bg-1);
            //   cursor: pointer;
            //   &:hover {
            //     background: var(--accent-color-1);
            //   }
            // }
            & > *:first-child {
              margin-right: auto;
            }
            @media only screen and (max-width: 996px) {
              & > * {
                width: 50% !important;
              }
            }
            @media only screen and (max-width: 960px) {
              margin-top: 60px;
            }

            @media only screen and (max-width: 640px) {
              // margin-top: 50px;
              flex-direction: column;
              gap: 15px;
              & > * {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
