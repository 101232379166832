.root {
  display: flex;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 7px 18px;
  min-height: 35px;
  border: none;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  gap: 5px;
  background: var(--gradient-bg-1);
  &:disabled {
    opacity: 0.5;
  }
  &.transparent {
    background: transparent;
    box-shadow: inset 0 0 2px var(--text-color-1);
    &:hover {
      background: #262b34;
    }
  }
  &:hover {
    background: var(--accent-color-1);
  }
  cursor: pointer;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 4px;
  }
  .label {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: -0.16px;
    color: #fff;
    // padding-top: 3px;
    // margin: 0 4px;
  }
}
