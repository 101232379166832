.root {
  position: relative;
  display: flex;
  &.defaultPLaceholder {
    input {
      padding: 10px 15px;
    }
    .label {
      display: none;
    }
  }
  @media only screen and (max-width: 980px) {
    flex: 1;
  }
  input {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 12px;
    font-size: 1.125rem;
    padding: 15px 15px 5px 15px;
    outline: none;
    display: flex;
    flex: 1;
    margin: auto;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    z-index: 1;
    &:disabled {
      color: var(--text-color-1);
    }

    &:focus {
      background-color: var(--main-bg);
      box-shadow: inset 0 0 2px var(--text-color-1);
    }

    &:focus + .label {
      top: 19%;
      font-size: 0.7rem;
    }

    &:valid + .label {
      top: 19%;
      font-size: 0.7rem;
    }
  }

  .label {
    cursor: text;
    position: absolute;
    left: 15px;
    top: 38%;
    background: transparent;
    color: var(--text-color-1);
    font-size: 1rem;
    transition: 0.3s;
    z-index: 2;
  }
}
