.root {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  .label {
    font-size: 0.875rem;
    color: var(--text-color-1);
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3px 8px;
    margin-right: 15px;
    label {
      color: #fff;
    }
    input {
      cursor: pointer;
      -webkit-appearance: none;
      position: absolute;
      width: 100%;
      height: 100%;
      &::before {
        content: "";
        position: absolute;
        height: 1.4em;
        width: 1.4em;
        top: -2px;
        right: -15px;
        border-radius: 50%;
        background: var(--field-bg);
        border: 1px solid var(--secondary-bg);
        outline: none;
      }
      &:checked {
        &::after {
          content: "";
          position: absolute;
          height: 0.8em;
          width: 0.8em;
          top: 0.25em;
          right: -10px;
          border-radius: 50%;
          background: var(--accent-color-2);
          outline: none;
        }
      }

      &.active {
        &::after {
          content: "";
          position: absolute;
          height: 0.8em;
          width: 0.8em;
          top: 0.25em;
          right: -10px;
          border-radius: 50%;
          background: var(--accent-color-2);
          outline: none;
        }
      }
    }
  }
}
