.root {
  display: flex;
  align-items: stretch;

  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    form {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 10px;
      gap: 45px;
      @media only screen and (max-width: 960px) {
        gap: 20px;
      }
      .row {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 20px;
        @media only screen and (max-width: 960px) {
          flex-direction: column;
          gap: 20px;
        }
        .title {
          color: var(--text-color-1);
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.0625rem;
          margin-bottom: 5px;
        }
        .fields {
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          .field {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 320px;
            height: 65px;
            @media only screen and (max-width: 1120px) {
              flex: 1;
            }
            @media only screen and (max-width: 960px) {
              width: 100%;
              height: 64px;
              flex: inherit;
            }
          }
        }

        .studentList {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          .studentItem {
            cursor: pointer;
            width: calc(25% - 15px);
            position: relative;
            &.isDisabled {
              opacity: 0.5;
            }
            @media only screen and (max-width: 1600px) {
              width: calc(33.3% - 15px);
            }
            @media only screen and (max-width: 1260px) {
              width: calc(50% - 15px);
            }
            @media only screen and (max-width: 980px) {
              width: 100%;
            }

            .delete {
              cursor: pointer;
              border: 2px solid var(--main-bg);
              content: "";
              position: absolute;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background: var(--accent-color-2);
              top: -7px;
              right: -7px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              font-size: 0.6em;
              font-weight: 600;
            }

            .wrapper {
              height: 118px;

              border: 1px dashed var(--secondary-bg);
              background: var(--secondary-bg);
              border-radius: 12px;
              display: flex;
              flex: 1;
              flex-direction: column;
              .row {
                margin: 12px;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                flex-direction: row;
                align-items: center;
                img {
                  width: 56px;
                  height: 56px;
                  object-fit: cover;
                  border-radius: 8px;
                  display: flex;
                }
                .details {
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  gap: 5px;
                  .name {
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: -0.3px;
                    color: #fefefe;
                  }
                  .role {
                    margin: 0;
                    font-size: 0.875rem;
                    font-weight: 400;
                    letter-spacing: 0;
                    color: var(--text-color-1);
                  }
                }
                .timestamp,
                .payment {
                  margin: 0;
                  font-size: 0.75rem;
                  font-weight: 400;
                  letter-spacing: 0;
                  color: var(--text-color-1);
                }
                .payment {
                  margin-left: auto;
                }
              }
            }
          }
          .add {
            // opacity: 0.5;

            cursor: pointer;
            transition: opacity 0.2s;
            &:hover {
              opacity: 0.7;
            }
            width: calc(25% - 15px);
            @media only screen and (max-width: 1600px) {
              width: calc(33.3% - 15px);
            }
            @media only screen and (max-width: 1260px) {
              width: calc(50% - 15px);
            }
            @media only screen and (max-width: 980px) {
              width: 100%;
            }
            .body {
              height: 118px;

              border: 1px dashed var(--text-color-1);
              border-radius: 12px;
              display: flex;
              flex: 1;

              justify-content: center;
              align-items: center;
              gap: 15px;
              // .icon {
              //   display: flex;
              //   justify-content: center;
              //   align-items: center;
              //   font-size: 5rem;
              //   padding: 15px;
              //   width: 30px;
              //   height: 30px;
              //   color: var(--text-color-1);
              //   border: 3px solid var(--text-color-1);
              //   border-radius: 50%;
              // }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 65px;
                height: 65px;
                border: 2px solid var(--text-color-1);
                border-radius: 50%;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 4px;
                  height: 26px;
                  position: absolute;
                  top: calc(50% - 13px);
                  left: calc(50% - 2px);
                  background: var(--text-color-1);
                }
                &:after {
                  content: "";
                  display: block;
                  height: 4px;
                  width: 26px;
                  position: absolute;
                  left: calc(50% - 13px);
                  top: calc(50% - 2px);
                  background: var(--text-color-1);
                }
              }
              .label {
                color: var(--text-color-1);
              }
            }
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    margin: 10px;
    margin-top: 100px;
    gap: 20px;
    justify-content: space-between;
    & > *:first-child {
      margin-right: auto;
    }
    @media only screen and (max-width: 996px) {
      & > * {
        width: 50% !important;
      }
    }
    @media only screen and (max-width: 960px) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 640px) {
      // margin-top: 50px;
      flex-direction: column;
      gap: 15px;
      & > * {
        width: 100% !important;
      }
    }
  }
}
