.root {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  .option {
    display: block;
    padding: 0;
    margin: 0;
    &:hover {
      label {
        color: var(--link);
      }
    }
    label {
      padding: 5px 15px;
      margin-left: 15px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      color: var(--text-color-1);
      font-size: 0.95rem;
      cursor: pointer;
      transition: all 0.15s;

      .questionCount {
        // position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        // top: -10px;
        // right: -10px;
        background: var(--gradient-bg-1);
        padding: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-size: 0.675rem;
        font-weight: 400;
        color: #f2ffff;
      }
    }
    input {
      visibility: hidden;
      display: none;
      &:checked + label {
        color: var(--link);
      }
    }
  }
}
