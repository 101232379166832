.root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  .label {
    cursor: text;
    // position: absolute;
    padding: 0 0 8px 15px;
    top: 12px;
    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 2;
  }
  .wrapper {
    border: 1px dashed #748091;
    padding: 15px;
    border-radius: 12px;
    position: relative;
    height: 100px;
    label {
      position: absolute;
      top: 0%;
      left: 0;
      // background: red;
      padding-left: 150px;
      width: calc(100% - 150px);
      height: 100%;
      display: flex;
      justify-content: flex-start;

      align-items: center;
      border-radius: 12px;
      color: #909295;
      letter-spacing: -0.3px;
      font-size: 1rem;

      &::before {
        content: "\2191";

        position: absolute;
        top: calc(50% - 40px);
        left: 40px;
        width: 80px;
        height: 80px;
        background: #0b0c0f;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5rem;
        color: #5b5b5b;
      }
    }
    input {
      display: none;
    }

    img {
      height: 100%;
      float: right;
    }
  }

  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
