.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  .wrapper {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;

    .select {
      cursor: pointer;
      background-color: var(--field-bg);
      color: var(--link);
      border: 2px px solid var(--field-bg);
      border-radius: 12px;
      font-size: 1.125rem;
      height: 65px;
      outline: none;
      display: flex;
      z-index: 1;
      position: relative;
      align-items: center;
      &.expanded {
        background-color: var(--dialog-bg);
      }
      .label {
        cursor: text;
        position: absolute;
        left: 15px;
        top: 10px;
        background: transparent;
        color: var(--text-color-1);
        font-size: 0.75rem;
        transition: 0.3s;
        z-index: 2;
      }
      .value {
        margin: 15px 40px 10px 15px;
        display: flex;
        flex: 1;
      }
      svg {
        position: absolute;
        right: 15px;
        top: 24px;
        transform: rotate(-90deg);
        opacity: 0.6;
        transition: all 0.25s;
        &.expanded {
          transform: rotate(90deg);
        }
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      top: 55px;
      z-index: 5;
      background-color: var(--dialog-bg);
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      max-height: 150px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        // border-right: 6px transparent solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &.show {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .option {
        display: block;
        padding: 0;
        margin: 0;
        label {
          padding: 10px 15px;
          display: flex;
          align-items: center;
          color: var(--text-color-1);
          font-size: 0.95rem;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            background: var(--field-bg);
          }
        }
        input {
          // visibility: hidden;
          display: none;
        }
      }
    }
  }

  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
