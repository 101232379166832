.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
        input {
          @media only screen and (max-width: 1600px) {
            min-width: 460px;
          }
          //   @media only screen and (max-width: 1164px) {
          //     min-width: 380px;
          //   }
          @media only screen and (max-width: 1080px) {
            width: 100%;
          }
        }
        // button {
        //   @media only screen and (max-width: 1600px) {
        //     margin-left: auto;
        //   }
        //   @media only screen and (max-width: 980px) {
        //     width: 100%;
        //   }
        // }
      }
      .contents {
        display: flex;
        flex-direction: column;
        // min-height: 400px;
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;
      }
      .pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;
        div {
          &:first-child {
            color: var(--text-color-1);
            span {
              color: white;
            }
          }
          &:not(:first-child) {
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 1.1rem;
            div {
              min-width: 60px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            button {
              outline: none;
              background: transparent;
              border: 1px solid var(--text-color-1);
              border-radius: 5px;
              width: 100px;
              color: var(--text-color-1);
              padding: 10px 7px;
              cursor: pointer;
              font-size: 0.9rem;
              -webkit-transition: all 0.25s;
              transition: all 0.25s;
              &:disabled {
                opacity: 0.5;
                cursor: auto;
              }
              &:not(:disabled):hover {
                color: white;
                background: var(--field-bg);
              }
            }

            color: var(--accent-color-1);

            text-align: center;
          }
        }
      }
    }
  }
}
