.root {
  display: flex;
  flex: 1;
  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    flex: 1;
    .classItem {
      width: calc(25% - 15px);
      @media only screen and (max-width: 1600px) {
        width: calc(33.3% - 15px);
      }
      @media only screen and (max-width: 1260px) {
        width: calc(50% - 15px);
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
      cursor: pointer;
      &:hover,
      &.selected {
        .wrapper {
          border: 1px solid var(--text-color-1);
        }
      }
      .wrapper {
        // margin: 10px;
        height: 260px;
        background-color: var(--field-bg);
        border-radius: 12px;
        display: flex;
        flex: 1;
        flex-direction: column;
        .title {
          margin: 35px 15px 35px 15px;
          font-size: 1rem;
          font-weight: 700;
          color: #f2ffff;
        }

        .row {
          margin: 10px 15px;
          .label {
            font-size: 0.6875rem;
            font-weight: 500;
            color: var(--text-color-1);
            padding: 3px 0;
            text-transform: capitalize !important;
          }
          .val {
            font-size: 0.875rem;
            font-weight: 700;
            color: #f2ffff;
          }
        }
      }
    }
  }
}
