.root {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  gap: 20px;
  .label {
    cursor: text;
    // position: absolute;

    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 2;
  }
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
      border-radius: 50%;
      object-fit: cover;
      width: 80px;
      height: 80px;
    }
    label {
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      height: 20px;
      // width: 170px;
      border: none;
      box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
      gap: 5px;
      background: var(--gradient-bg-1);
      cursor: pointer;
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: -0.18px;
      color: #fff;
    }
    input {
      display: none;
    }
  }

  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
