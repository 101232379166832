.root {
  display: flex;
  // width: 70%;
  // margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 40px 0 40px;
  z-index: 20;
  background: transparent;
  .user {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    .avatar {
      width: 38px;
      height: 38px;
      margin-right: 5px;
      img {
        width: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 5px;
      .name {
        font-size: 0.875rem;
        font-weight: 900;
        color: #f2ffff;
        padding: 3px 0;
      }
      .role {
        font-size: 0.6875rem;
        font-weight: 900;
        color: #8b8ea5;
        padding: 3px 0;
      }
    }
    .menu {
      position: absolute;
      cursor: initial;
      width: 100%;
      top: 40px;
      left: 0;
      background: var(--field-bg);
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 0;
      border-radius: 8px;
      & > * {
        margin: 2px 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        color: #8b8ea5;
        &:hover {
          color: var(--link);
        }
        .icon {
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          line-height: 1rem;
        }
      }
    }
  }
}
