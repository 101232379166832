.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        min-height: 400px;
        width: 100%;
        .form {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin: 10px;
          gap: 45px;
          @media only screen and (max-width: 960px) {
            gap: 20px;
          }
          form {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .row {
              display: flex;
              gap: 15px;
              flex-wrap: wrap;
              flex-direction: column;
              margin-top: 20px;
              @media only screen and (max-width: 960px) {
                flex-direction: column;
                gap: 20px;
              }
              .title {
                color: var(--text-color-1);
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.0625rem;
                margin-bottom: 5px;
              }
              .subtitle {
                margin: 0;
                font-size: 0.875rem;
                font-weight: 400;
                letter-spacing: 0;
                color: var(--text-color-1);
              }
              .toggle {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 22px;

                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: var(--field-bg);
                  -webkit-transition: 0.4s;
                  transition: 0.4s;
                  &.round {
                    border-radius: 34px;
                  }

                  &.round:before {
                    border-radius: 50%;
                  }

                  &.on {
                    &:before {
                      -webkit-transform: translateX(26px);
                      -ms-transform: translateX(26px);
                      transform: translateX(26px);
                    }
                  }
                }

                .slider:before {
                  position: absolute;
                  content: "";
                  height: 26px;
                  width: 26px;
                  left: 4px;
                  bottom: -2px;
                  background: var(--gradient-bg-1);
                  -webkit-transition: all 0.4s;
                  transition: all 0.4s;
                }

                input:checked + .slider:before {
                  -webkit-transform: translateX(26px);
                  -ms-transform: translateX(26px);
                  transform: translateX(26px);
                }
                .label {
                  position: absolute;
                  right: -38px;
                  top: 3px;
                  font-size: 1.2rem;
                  font-weight: 300;
                  color: var(--link);
                }
              }
              .fields {
                display: flex;
                gap: 30px;
                flex-wrap: wrap;
                .field {
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  width: 320px;
                  height: 65px;
                  @media only screen and (max-width: 1120px) {
                    flex: 1;
                  }
                  @media only screen and (max-width: 960px) {
                    width: 100%;
                    height: 64px;
                    flex: inherit;
                  }
                }
              }
            }
          }

          .maintenanceContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            flex-direction: column;
            .title {
              color: var(--text-color-1);
              font-size: 1.5rem;
              font-weight: 400;
              line-height: 1.0625rem;
              margin-bottom: 5px;
            }
            .maintenanceList {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              flex-direction: row;
              .maintenanceItem {
                width: calc(25% - 15px);
                @media only screen and (max-width: 1600px) {
                  width: calc(33.3% - 15px);
                }
                @media only screen and (max-width: 1260px) {
                  width: calc(50% - 15px);
                }
                @media only screen and (max-width: 980px) {
                  width: 100%;
                }
                .maintenanceItemWrapper {
                  height: 294px;
                  background-color: var(--field-bg);
                  border-radius: 12px;
                  display: flex;
                  flex: 1;
                  flex-direction: column;

                  border: 1px solid var(--field-bg);

                  .row {
                    margin: 10px;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-between;
                    flex-wrap: wrap;
                    .content {
                      display: flex;
                      flex-direction: column;
                      flex: 1;
                      gap: 5px;
                      .body {
                        &.message {
                          height: 42px;
                          letter-spacing: -0.32px;
                          line-height: 1.3rem;
                          overflow: hidden;

                          text-overflow: ellipsis;
                          word-break: break-word;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                        }
                        &.notes {
                          height: 18px;
                          letter-spacing: -0.32px;
                          line-height: 1.3rem;
                          overflow: hidden;

                          text-overflow: ellipsis;
                          word-break: break-word;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 1;
                        }
                      }
                      span {
                        &:first-child {
                          color: var(--text-color-1);
                          font-size: 0.875rem;
                          font-weight: 300;
                        }
                      }
                    }
                  }
                  .actions {
                    display: flex;
                    gap: 0px;
                    margin: auto 0 0 0;
                    background: var(--gradient-bg-1);
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    position: relative;
                    .button {
                      display: flex;
                      flex: 1 1;
                      justify-content: center;
                      align-items: center;
                      background: transparent;
                      padding: 12px 0;
                      font-size: 1rem;
                      font-weight: 700;
                      color: #f2ffff;
                      cursor: pointer;
                      &:first-child {
                        border-right: 1px solid var(--field-bg);
                      }
                      &:not(:first-child) {
                        border-left: 1px solid var(--field-bg);
                      }
                    }
                  }
                }
              }
            }
          }

          .actions {
            display: flex;
            margin: 100px 10px 100px 10px;
            // margin-top: auto;
            gap: 20px;
            justify-content: space-between;
            & > *:last-child {
              margin-left: auto;
            }
            @media only screen and (max-width: 996px) {
              & > * {
                width: 50% !important;
              }
            }
            @media only screen and (max-width: 960px) {
              margin-top: 60px;
            }

            @media only screen and (max-width: 640px) {
              // margin-top: 50px;
              flex-direction: column;
              gap: 15px;
              & > * {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
