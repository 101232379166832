.root {
  display: flex;
  flex-wrap: wrap;
  .week {
    width: 25%;
    @media only screen and (max-width: 1600px) {
      width: 33.3%;
    }
    @media only screen and (max-width: 1260px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
    }

    .body {
      margin: 10px;
      height: 210px;
      background-color: var(--secondary-bg);
      border-radius: 12px;
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;
      &.hovered {
        opacity: 0.3;
        box-shadow: inset 0 0 3px var(--text-color-1);
      }
      .orderHandler {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        color: var(--text-color-1);
        font-size: 0.65rem;
        cursor: grab;
        margin-bottom: 15px;
        gap: 7px;
        .icon {
          position: relative;
          width: 17px;
          height: 5px;
          //   border: 1px solid #f2ffff;
          &::before {
            content: "...";
            position: absolute;
            top: -4px;
            left: 0;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 0;
          }
          &::after {
            content: "...";
            position: absolute;
            top: 2px;
            left: 0;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 0;
          }
        }
      }
      .subtitle {
        margin: 0 15px;
        font-size: 0.85rem;
        font-weight: 300;
        color: var(--text-color-1);
        padding: 3px 0;
        text-transform: capitalize !important;
      }
      .title {
        margin: 0 15px 5px 15px;
        font-size: 1rem;
        font-weight: 700;
        color: #f2ffff;
        word-break: break-all;
      }
      .description {
        color: var(--text-color-1);
        font-size: 1rem;
        font-weight: 300;
        height: 80px;
        letter-spacing: -0.32px;
        line-height: 1.3rem;
        overflow: hidden;
        padding: 5px 0;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        margin: auto 10px 10px 10px;
      }
      &:hover {
        .actions {
          display: flex;
        }
      }
      .actions {
        display: none;
        margin-top: auto;
        gap: 2px;
        border-radius: 3px;
        position: absolute;
        width: 100%;
        bottom: 0;
        .button {
          background: var(--gradient-bg-1);
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          padding: 10px 0;
          font-size: 0.875rem;
          font-weight: 700;
          color: #f2ffff;
          cursor: pointer;

          &:first-child {
            border-bottom-left-radius: 15px;
          }
          &:last-child {
            border-bottom-right-radius: 15px;
          }
        }
      }
    }
  }

  .add {
    // opacity: 0.5;
    width: 25%;
    cursor: pointer;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
    @media only screen and (max-width: 1600px) {
      width: 33.3%;
    }
    @media only screen and (max-width: 1260px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
    }
    .body {
      margin: 10px;
      height: 208px;

      border: 1px dashed var(--text-color-1);
      border-radius: 12px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      @media only screen and (max-width: 980px) {
        height: 160px;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        border: 4px solid var(--text-color-1);
        border-radius: 50%;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 4px;
          height: 26px;
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 2px);
          background: var(--text-color-1);
        }
        &:after {
          content: "";
          display: block;
          height: 4px;
          width: 26px;
          position: absolute;
          left: calc(50% - 13px);
          top: calc(50% - 2px);
          background: var(--text-color-1);
        }
      }
      .label {
        color: var(--text-color-1);
      }
    }
  }
}
