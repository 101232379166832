.root {
  position: relative;

  padding-right: 15px;
  margin-top: 30px;

  .messageComposer {
    display: flex;
    background: var(--field-bg);
    margin: 10px 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 25px;
    form {
      display: flex;
      flex: 1;
      gap: 20px;
      input {
        &:focus {
          outline: none;
        }
        flex: 1;
        line-height: 20px;
        padding: 8px 0 8px 8px;
        max-width: 100%;
        min-width: 0px;
        width: 100%;
        border-radius: 7px;
        border: none;
      }
      button {
        background: var(--gradient-bg-1);
        border: none;
        outline: none;
        border-radius: 6px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:disabled {
          opacity: 0.5;
        }

        cursor: pointer;
        svg {
          width: 15px;
          path {
          }
          fill: none;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    .weekSelector {
      width: 300px;

      @media only screen and (max-width: 780px) {
        width: 100%;
      }
    }
  }
}
