.root {
  display: flex;
  flex: 1;
  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    flex: 1;
    .courseItem {
      width: calc(25% - 15px);
      @media only screen and (max-width: 1600px) {
        width: calc(33.3% - 15px);
      }
      @media only screen and (max-width: 1260px) {
        width: calc(50% - 15px);
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
      .title {
        color: #fff;
        font-weight: 700;
        letter-spacing: -0.33px;
        margin: 10px 0;
        font-size: 1.2rem;
      }
      .wrapper {
        height: 394px;
        background-color: var(--secondary-bg);
        border-radius: 12px;
        display: flex;
        flex: 1;
        flex-direction: column;
        // margin: 10px;
        border: 1px solid var(--field-bg);
        transition: all 0.15s;
        .avatar {
          height: 150px;
          margin: 10px;
          position: relative;
          transition: all 0.2s;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 7px;
          }
        }
        .subtitle {
          color: var(--text-color-1);
          font-size: 0.875rem;
          font-weight: 300;
          margin: 10px 20px 2px 20px;
        }
        .title {
          color: #fff;
          font-weight: 700;
          letter-spacing: -0.33px;
          margin: 0 10px 20px 20px;
          font-size: 1.2rem;
        }
        .description {
          color: var(--text-color-1);
          font-size: 1rem;
          font-weight: 300;
          height: 120px;
          letter-spacing: -0.32px;
          line-height: 1.3rem;
          overflow: hidden;
          padding: 5px 0;
          text-overflow: ellipsis;
          word-break: break-word;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          margin: 20px;
        }
      }
    }

    .classItem {
      width: calc(25% - 15px);
      @media only screen and (max-width: 1600px) {
        width: calc(33.3% - 15px);
      }
      @media only screen and (max-width: 1260px) {
        width: calc(50% - 15px);
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
      }
      .title {
        color: #fff;
        font-weight: 700;
        letter-spacing: -0.33px;
        margin: 10px 0;
        font-size: 1.2rem;
      }
      .wrapper {
        height: 394px;
        background-color: var(--secondary-bg);
        border-radius: 12px;
        display: flex;
        flex: 1;
        flex-direction: column;
        // margin: 10px;
        border: 1px solid var(--field-bg);
        transition: all 0.15s;

        .title {
          margin: 35px 15px 35px 15px;
          font-size: 1rem;
          font-weight: 700;
          color: #f2ffff;
        }

        .row {
          margin: 10px 15px;
          .label {
            font-size: 0.6875rem;
            font-weight: 500;
            color: var(--text-color-1);
            padding: 3px 0;
            text-transform: capitalize !important;
          }
          .val {
            font-size: 0.875rem;
            font-weight: 700;
            color: #f2ffff;
          }
        }
      }
    }
  }
}
