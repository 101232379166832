.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
      }
      .contents {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        width: 100%;

        .tabs {
          margin: 10px 10px 50px 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
          border-bottom: 1px solid #303030;

          .tab {
            font-size: 1.2rem;
            font-weight: bold;
            padding: 15px 0;
            letter-spacing: -0.3px;

            color: #fefefe;
            cursor: pointer;
            position: relative;
            transition: color 0.2s;
            &.active {
              color: var(--accent-color-1);
              &::after {
                content: "";
                width: 100%;
                bottom: -2px;
                left: 0;
                height: 2px;
                background: var(--accent-color-1);
                position: absolute;
              }
            }
            &:hover {
              color: var(--accent-color-1);
              // border-bottom: 2px solid var(--accent-color-1);
            }
          }
        }
      }
    }
  }
}
