.root {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .lecturerSelect {
        position: relative;
        cursor: pointer;
        width: calc(25% - 15px);
        @media only screen and (max-width: 1600px) {
          width: calc(33.3% - 15px);
        }
        @media only screen and (max-width: 1260px) {
          width: calc(50% - 15px);
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
        }
        .select {
          background-color: var(--field-bg);
          color: var(--link);
          border: 2px px solid var(--field-bg);
          border-radius: 12px;
          font-size: 1.125rem;

          height: 84px;

          outline: none;
          display: flex;

          z-index: 1;
          position: relative;
          label {
            cursor: text;
            position: absolute;
            left: 15px;
            top: 10px;
            background: transparent;
            color: var(--text-color-1);
            font-size: 0.75rem;
            transition: 0.3s;
            z-index: 2;
            border: none;
            outline: none;
          }
          .value {
            display: flex;
            flex: 1;
            // background-color: red;
            align-items: center;
            margin: 23px 30px 15px 15px;
            border: none;
            outline: none;
            gap: 12px;
            overflow: hidden;
            .valueItem {
              display: flex;
              align-items: center;
              gap: 3px;
              flex-wrap: nowrap;
              color: white;
              font-size: 1rem;
              overflow-wrap: break-word;
              img {
                width: 38px;
                height: 38px;
                object-fit: cover;
                border-radius: 3px;
              }
            }
          }
          svg {
            position: absolute;
            right: 10px;
            top: 33px;
            transform: rotate(-90deg);
            opacity: 0.6;
            transition: all 0.25s;
            &.expanded {
              transform: rotate(90deg);
            }
          }
        }

        .options {
          display: none;
          position: absolute;
          width: 100%;
          top: 72px;
          z-index: 5;
          background-color: var(--field-bg);
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          max-height: 300px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 4px;
            margin-right: 2px;
          }
          /* Track */
          &::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 5px grey;
            border-radius: 8px;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #48515d;

            // border-right: 6px transparent solid;
            background-clip: padding-box;
          }
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          &.show {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
          .option {
            color: var(--text-color-1);
            cursor: pointer;
            transition: all 0.25s;
            padding: 0 15px;
            &:hover,
            &.active {
              background-color: var(--main-bg);
            }
            &.disabled {
              label {
                opacity: 0.4;
              }
            }
            label {
              padding: 7px 0;
              display: flex;
              align-items: center;
              gap: 10px;
              cursor: pointer;
              img {
                width: 38px;
                height: 38px;
                object-fit: cover;
                border-radius: 3px;
              }
            }
          }
        }
      }

      .add {
        // opacity: 0.5;

        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.7;
        }
        width: calc(25% - 15px);
        @media only screen and (max-width: 1600px) {
          width: calc(33.3% - 15px);
        }
        @media only screen and (max-width: 1260px) {
          width: calc(50% - 15px);
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
        }
        .body {
          height: 84px;

          border: 1px dashed var(--text-color-1);
          border-radius: 12px;
          display: flex;
          flex: 1;

          justify-content: center;
          align-items: center;
          gap: 15px;
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border: 3px solid var(--text-color-1);
            border-radius: 50%;
            position: relative;
            &:before {
              content: "";
              display: block;
              width: 4px;
              height: 22px;
              position: absolute;
              top: calc(50% - 11px);
              left: calc(50% - 2px);
              background: var(--text-color-1);
            }
            &:after {
              content: "";
              display: block;
              height: 4px;
              width: 22px;
              position: absolute;
              left: calc(50% - 11px);
              top: calc(50% - 2px);
              background: var(--text-color-1);
            }
          }
          .label {
            color: var(--text-color-1);
          }
        }
      }
    }
  }
}
