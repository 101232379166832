.root {
  display: flex;
  flex: 1;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  .label {
    color: var(--text-color-1);
    padding: 10px 0;
  }
  .wrapper {
    display: flex;

    position: relative;
    flex-direction: column;
    width: 180px;

    .select {
      cursor: pointer;
      background-color: var(--dialog-bg);
      color: var(--link);
      border: 2px px solid var(--dialog-bg);
      border-radius: 12px;
      font-size: 1.125rem;
      height: 40px;
      outline: none;
      display: flex;
      z-index: 1;
      position: relative;
      align-items: center;
      .value {
        margin: 6px 40px 6px 15px;
        display: flex;
        flex: 1;
      }
      svg {
        position: absolute;
        right: 15px;
        top: 11px;
        transform: rotate(-90deg);
        opacity: 0.6;
        transition: all 0.25s;
        &.expanded {
          transform: rotate(90deg);
        }
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      top: 30px;
      z-index: 5;
      background-color: var(--dialog-bg);
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      max-height: 150px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        // border-right: 6px transparent solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &.show {
        display: flex;
        flex-direction: column;
        // gap: 5px;
      }
      .option {
        display: block;
        padding: 0;
        margin: 0;
        label {
          padding: 6px 15px;
          display: flex;
          align-items: center;
          color: var(--text-color-1);
          font-size: 0.95rem;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            color: var(--link);
          }
        }
        input {
          // visibility: hidden;
          display: none;
        }
      }
    }
  }
}
