.root {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  // margin-bottom: 30px;
  .label {
    font-size: 0.875rem;
    color: var(--text-color-1);
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 218px;
    height: 66px;
    padding: 15px;
    background: var(--secondary-bg);
    border: 1px solid var(--secondary-bg);
    border-radius: 12px;
    flex: 1;

    @media only screen and (max-width: 1024px) {
      flex: 1 1 34%;
    }
    &.disabled {
      // background: transparent;
      border: 1px dashed var(--field-bg);
      // label {
      //   color: var(--field-bg);
      // }
    }
    &.active {
      background: #323944;
      border: 1px solid #663ca0;
    }
    label {
      color: var(--link);
      margin-left: 35px;
    }
    input {
      cursor: pointer;
      -webkit-appearance: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: -3px;
      left: -5px;
      border-radius: 12px;

      &::before {
        content: "";
        position: absolute;
        height: 1.4em;
        width: 1.4em;
        top: 2.75em;
        left: 15px;
        border-radius: 50%;
        background: var(--field-bg);
        border: 1px solid var(--secondary-bg);
        outline: none;
      }
      &:checked {
        &::after {
          content: "";
          position: absolute;
          height: 0.8em;
          width: 0.8em;
          top: 3.13em;
          left: 20px;
          border-radius: 50%;
          background: var(--accent-color-2);
          outline: none;
        }
      }

      &.active {
        &::after {
          content: "";
          position: absolute;
          height: 0.8em;
          width: 0.8em;
          top: 3.13em;
          left: 20px;
          border-radius: 50%;
          background: var(--accent-color-2);
          outline: none;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    gap: 10px;
    .permissions {
      color: var(--link);
    }
    span {
      color: var(--text-color-1);
      font-size: 0.85rem;
      line-height: 1.25rem;
    }
  }
  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
