.root {
  display: flex;
  background: var(--field-bg);
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  &.loader {
    // width: 100%;
    // height: 100%;
    position: relative;
    background: var(--secondary-bg);
    overflow: hidden;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: loading-animation 1s ease-in-out infinite;
      animation: loading-animation 1s ease-in-out infinite;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);

      background: -moz-linear-gradient(
        -45deg,
        rgba(25, 26, 35, 1) 0%,
        rgba(25, 26, 35, 0.58) 30%,
        rgba(43, 43, 43, 0.3) 50%,
        rgba(25, 26, 35, 0.58) 70%,
        rgba(25, 26, 35, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        color-stop(0%, rgba(25, 26, 35, 1)),
        color-stop(30%, rgba(25, 26, 35, 0.58)),
        color-stop(50%, rgba(43, 43, 43, 0.3)),
        color-stop(70%, rgba(25, 26, 35, 0.58)),
        color-stop(100%, rgba(25, 26, 35, 1))
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(25, 26, 35, 1) 0%,
        rgba(25, 26, 35, 0.58) 30%,
        rgba(43, 43, 43, 0.3) 50%,
        rgba(25, 26, 35, 0.58) 70%,
        rgba(25, 26, 35, 1) 100%
      );
      background: -o-linear-gradient(
        -45deg,
        rgba(25, 26, 35, 1) 0%,
        rgba(25, 26, 35, 0.58) 30%,
        rgba(43, 43, 43, 0.3) 50%,
        rgba(25, 26, 35, 0.58) 70%,
        rgba(25, 26, 35, 1) 100%
      );
      background: -ms-linear-gradient(
        -45deg,
        rgba(25, 26, 35, 1) 0%,
        rgba(25, 26, 35, 0.58) 30%,
        rgba(43, 43, 43, 0.3) 50%,
        rgba(25, 26, 35, 0.58) 70%,
        rgba(25, 26, 35, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(25, 26, 35, 1) 0%,
        rgba(25, 26, 35, 0.58) 30%,
        rgba(43, 43, 43, 0.3) 50%,
        rgba(25, 26, 35, 0.58) 70%,
        rgba(25, 26, 35, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#191a23', endColorstr='#191a23',GradientType=1 );
    }
  }

  .header {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;
    align-items: center;
    padding: 15px 50px 15px 15px;
    position: relative;
    span {
      flex: 1 1 100%;
      text-align: center;
    }
    .icon {
      position: absolute;
      top: 21px;
      right: 14px;
      cursor: pointer;
      transform: rotate(-90deg);

      width: 24px;
      height: 24px;
      opacity: 0.3;
      transition: transform 0.15s;
    }

    &.expanded {
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      text-align: left;
      tr {
        border-bottom: 1px solid var(--text-color-1);
        th {
          padding: 20px;
          &:first-child {
            // width: 20%;
            border-right: 1px solid var(--text-color-1);
          }
          &:not(:first-child) {
            // width: 40%;
          }
        }
      }
    }
    tbody {
      text-align: left;
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid var(--secondary-bg);
        }

        td {
          padding: 20px;
          img {
            width: 100%;
          }

          &:first-child {
            width: 18%;
            border-right: 1px solid var(--text-color-1);
            word-break: break-all;
          }
          &:not(:first-child) {
            width: 41%;
            vertical-align: top;
          }
        }
      }
    }
  }
}

@keyframes loading-animation {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
