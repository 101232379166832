.root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  .select {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 12px;
    font-size: 1.125rem;
    padding: 23px 15px 5px 15px;
    line-height: 2.5rem;
    outline: none;
    display: flex;
    flex: 1 1;
    z-index: 1;
    cursor: pointer;
    svg {
      position: absolute;
      right: 10px;
      top: 24px;
      transform: rotate(-90deg);
      opacity: 0.6;
      transition: all 0.25s;
      &.expanded {
        transform: rotate(90deg);
      }
    }
    .value {
      line-height: 2.3rem;
      text-transform: capitalize;
      height: 36px;
    }
  }
  .label {
    cursor: text;
    position: absolute;
    left: 15px;
    top: 20%;
    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 2;
  }
  .options {
    display: none;
    position: absolute;
    width: 100%;
    top: 56px;
    background-color: var(--field-bg);
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &.show {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .option {
      color: var(--text-color-1);
      cursor: pointer;
      transition: all 0.25s;
      padding: 0 15px;
      &:hover,
      &.selected {
        background-color: var(--main-bg);
      }
      &.disabled {
        label {
          opacity: 0.4;
        }
      }
      label {
        padding: 7px 0;
        display: block;
        cursor: pointer;
      }
    }
  }
  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
