// .root {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;

//   position: absolute;
//   width: 100%;
//   height: 100%;

//   top: 0;
//   right: 0;
//   z-index: 999;
//   background: var(--main-bg);
//   -webkit-transition: all 0.15s;
//   -o-transition: all 0.15s;
//   transition: all 0.15s;
//   &.collapsed {
//     width: calc(100vw - 50px);
//   }
//   .loader {
//     max-width: 15rem;
//     width: 100%;
//     height: auto;
//     stroke-linecap: round;
//     position: fixed;
//     top: 25%;
//   }
//   circle {
//     fill: none;
//     stroke-width: 3.5;

//     -webkit-animation-name: preloader;
//     -moz-animation-name: preloader;
//     -ms-animation-name: preloader;
//     animation-name: preloader;

//     -webkit-animation-duration: 3s;
//     -moz-animation-duration: 3s;
//     -ms-animation-duration: 3s;
//     animation-duration: 3s;

//     -webkit-animation-iteration-count: infinite;
//     -moz-animation-iteration-count: infinite;
//     -ms-animation-iteration-count: infinite;
//     animation-iteration-count: infinite;

//     -webkit-animation-timing-function: ease-in-out;
//     -moz-animation-timing-function: ease-in-out;
//     -ms-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out;

//     -webkit-transform-origin: 170px 170px;
//     -moz-transform-origin: 170px 170px;
//     -ms-transform-origin: 170px 170px;
//     transform-origin: 170px 170px;

//     will-change: transform;

//     &:nth-of-type(1) {
//       stroke-dasharray: 550px;
//     }

//     &:nth-of-type(2) {
//       stroke-dasharray: 500px;
//     }

//     &:nth-of-type(3) {
//       stroke-dasharray: 450px;
//     }

//     &:nth-of-type(4) {
//       stroke-dasharray: 300px;
//     }

//     @for $i from 1 through 4 {
//       &:nth-of-type(#{$i}) {
//         -webkit-animation-delay: -#{$i * 0.15}s;
//         -moz-animation-delay: -#{$i * 0.15}s;
//         -ms-animation-delay: -#{$i * 0.15}s;
//         animation-delay: -#{$i * 0.15}s;
//       }
//     }
//   }
// }

// @keyframes preloader {
//   0% {
//     -webkit-transform: rotate(0deg);
//     -moz-transform: rotate(0deg);
//     -ms-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   50% {
//     -webkit-transform: rotate(360deg);
//     -moz-transform: rotate(360deg);
//     -ms-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

.root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  position: fixed;
  width: calc(100vw - 240px);
  height: 100vh;

  top: 0;
  right: 0;
  z-index: 999;
  background: var(--main-bg);
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  @media only screen and (max-width: 780px) {
    width: calc(100vw - 50px);
  }
  // &.collapsed {
  //   width: calc(100vw - 50px);
  // }
  figure {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 6.25em;
    height: 6.25em;
    animation: rotate 1.8s linear infinite;
  }
  .white {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    animation: flash 1.8s linear infinite;
    opacity: 0;
  }
  .dot {
    position: absolute;
    margin: auto;
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
  }
  .dot:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 0;
    background: #cc33ff;
    animation: dotsY 1.8s linear infinite;
  }
  .dot:nth-child(3) {
    left: 0;
    right: 0;
    top: 0;
    background: #fefefe;
    animation: dotsX 1.8s linear infinite;
  }
  .dot:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 0;
    background: #8e34fc;
    animation: dotsY 1.8s linear infinite;
  }
  .dot:nth-child(5) {
    left: 0;
    right: 0;
    bottom: 0;
    background: #fc5a83;
    animation: dotsX 1.8s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    10% {
      width: 6.25em;
      height: 6.25em;
    }
    66% {
      width: 2.4em;
      height: 2.4em;
    }
    100% {
      transform: rotate(360deg);
      width: 6.25em;
      height: 6.25em;
    }
  }

  @keyframes dotsY {
    66% {
      opacity: 0.1;
      width: 2.4em;
    }
    77% {
      opacity: 1;
      width: 0;
    }
  }
  @keyframes dotsX {
    66% {
      opacity: 0.1;
      height: 2.4em;
    }
    77% {
      opacity: 1;
      height: 0;
    }
  }

  @keyframes flash {
    33% {
      opacity: 0;
      border-radius: 0%;
    }
    55% {
      opacity: 0.6;
      border-radius: 100%;
    }
    66% {
      opacity: 0;
    }
  }
}
