.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        min-height: 400px;
        width: 100%;
        flex-direction: column;

        form {
          display: flex;
          flex: 1;
          flex-direction: column;

          margin: 10px;
          gap: 25px;
          @media only screen and (max-width: 960px) {
            gap: 20px;
          }
          .row {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            @media only screen and (max-width: 960px) {
              flex-direction: column;
              gap: 20px;
            }
            .field {
              display: flex;
              position: relative;
              flex-direction: column;
              width: 320px;
              height: 65px;
              @media only screen and (max-width: 1120px) {
                flex: 1;
              }
              @media only screen and (max-width: 960px) {
                width: 100%;
                height: 64px;
                flex: inherit;
              }
            }
          }
        }
        .actions {
          display: flex;
          margin: 10px;
          margin-top: auto;
          justify-content: space-between;
          @media only screen and (max-width: 960px) {
            margin-top: 50px;
          }
          @media only screen and (max-width: 640px) {
            // margin-top: 50px;
            flex-direction: column;
            gap: 15px;
            & > * {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
