.root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  textarea {
    font-family: var(--font-stack);
    background-color: var(--field-bg);
    border: 2px px solid var(--field-bg);
    font-size: 1.125rem;
    padding: 25px 15px 5px 15px;
    outline: none;
    z-index: 1;
    background-color: var(--field-bg);
    border: none;
    resize: none;
    border-radius: 12px;
    outline: none;
    font-family: var(--font-stack);
    color: white;
    line-height: 1.2rem;
    font-weight: normal;

    &::-webkit-scrollbar {
      width: 4px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      // border-right: 6px transparent solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &:disabled {
      color: var(--text-color-1);
    }

    &:focus {
      background-color: var(--main-bg);
      box-shadow: inset 0 0 2px var(--text-color-1);
    }
  }
  .label {
    cursor: text;
    position: absolute;
    left: 15px;
    top: 12px;
    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 2;
  }
  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: var(--error-color);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
