.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  .item {
    font-weight: bold;
    font-size: 1rem;
    margin: 20px 0;
    height: 30px;
    padding-left: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-left: 3px solid var(--secondary-bg);
    transition: 0.3s ease border;
    cursor: pointer;
    color: white;
    transition: all 0.25s;
    .name {
      margin-top: 5px;
    }
    .icon {
      width: 22px;
      height: 22px;
      // background-color: rgba(0, 255, 255, 0.417);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &.users {
        background-image: url("../../assets/icons/user1.svg");
      }
      &.courses {
        background-image: url("../../assets/icons/courses.svg");
      }
      &.classes {
        background-image: url("../../assets/icons/classes.svg");
      }
      &.maintenance {
        background-image: url("../../assets/icons/maintenance.svg");
      }
      &.help {
        background-image: url("../../assets/icons/help.svg");
      }
      &.support {
        background-image: url("../../assets/icons/support.svg");
      }
      &.history {
        background-image: url("../../assets/icons/history.svg");
      }

      filter: brightness(0) saturate(100%) invert(54%) sepia(17%) saturate(349%)
        hue-rotate(174deg) brightness(80%) contrast(91%);
    }

    &.active,
    &:hover {
      .icon {
        filter: brightness(0) saturate(100%) invert(33%) sepia(78%)
          saturate(5372%) hue-rotate(272deg) brightness(88%) contrast(101%);
      }

      color: var(--accent-color-1) !important;
      border-left-color: var(--accent-color-1);
    }

    @media only screen and (max-width: 780px) {
      padding-left: 0;
      border-left-width: 0;
      transition: none;

      &.active,
      &:hover {
        border-left-width: 0;
      }
      .name {
        display: none;
      }
    }
  }
  &.collapsed {
    .item {
      padding-left: 0;
      border-left-width: 0;
      transition: none;
      .name {
        display: none;
      }

      &.active,
      &:hover {
        border-left-width: 0;
      }
    }
  }
}
