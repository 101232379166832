.root {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    flex: 1;
    height: 100vh;
  }
  .left {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      position: absolute;
      z-index: -1;
    }
    .title {
      font-size: 3.625rem;
      letter-spacing: -0.87px;
      line-height: 68px;
      color: #7c725a;
      margin-top: 250px;
    }
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }

  .right {
    width: calc(45% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 100px;
    position: relative;
    @media only screen and (max-width: 1460px) {
      width: calc(50% - 200px);
    }
    @media only screen and (max-width: 1200px) {
      width: calc(65% - 200px);
    }
    @media only screen and (max-width: 960px) {
      width: auto;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 30px;
    }
    .logo {
      width: 280px;
      height: auto;
      margin-top: 220px;
    }
    .form {
      display: flex;
      flex: 1;
      max-width: 500px;
      padding: 50px 0;
      @media only screen and (max-width: 960px) {
        width: 100%;
      }
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: auto;
      padding: 20px 0;
      @media only screen and (max-width: 960px) {
        width: 100%;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          span {
            color: var(--text-color-1);
            font-size: 0.75rem;
            &.link {
              color: white;
              cursor: pointer;
              text-decoration: underline;
              font-size: 0.75rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
