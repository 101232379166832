.root {
  background-color: var(--secondary-bg);
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: 220px;
  height: calc(100vh - 63px);
  padding: 30px 20px 33px 0;
  transition: width 0.15s;
  position: fixed;
  &.collapsed {
    display: flex;
    width: 50px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 780px) {
    display: flex;
    width: 50px;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  // sidebar logo

  .logo {
    margin: 0 0 23px 28px;
    display: block;
    width: 130px;
    height: 46px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
    }
    &.collapsed {
      margin-left: 0;
      width: 26px;
      overflow: hidden;

      img {
        width: 100px;
        position: absolute;
      }
    }

    @media only screen and (max-width: 780px) {
      margin-left: 0;
      width: 26px;
      overflow: hidden;

      img {
        width: 100px;
        position: absolute;
        object-fit: cover;
        object-position: left;
      }
    }
  }
  //sidebar expander

  .expander {
    margin-bottom: 55px;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 780px) {
      display: none;
    }

    span {
      cursor: pointer;
      display: flex;
      padding-left: 12px;
      justify-content: center;
      align-items: center;
    }
  }
}
