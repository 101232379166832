.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: var(--dialog-bg);
  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2.5rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--link);
    font-weight: 300;
  }
  .wrapper {
    @media only screen and (max-width: 1120px) {
      margin-left: 0;
    }

    margin-left: 240px;
    transition: margin-left 0.15s;
    max-width: var(--max-container-width);
    height: calc(100% - 180px);
    width: calc(100% - 40px);
    margin-top: 90px;
    padding: 40px;
    // background: var(--field-bg);
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;
      padding: 0;

      .title {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 15px 0;
        letter-spacing: -0.3px;
        color: #fefefe;
        cursor: pointer;
        position: relative;
        transition: color 0.2s;
      }
    }

    .body {
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      padding: 15px 0;
      gap: 20px;
      align-content: flex-start;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
        margin-right: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #48515d;

        // border-right: 6px transparent solid;
        background-clip: padding-box;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        flex: 1;
        .row {
          display: flex;
          gap: 15px;
          flex-wrap: wrap;
          flex-direction: column;
          margin-top: 20px;
          @media only screen and (max-width: 960px) {
            flex-direction: column;
            gap: 20px;
          }
          .title {
            color: var(--text-color-1);
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.0625rem;
            margin-bottom: 5px;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
          .toggle {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 22px;

            input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: var(--field-bg);
              -webkit-transition: 0.4s;
              transition: 0.4s;
              &.round {
                border-radius: 34px;
              }

              &.round:before {
                border-radius: 50%;
              }

              &.on {
                &:before {
                  -webkit-transform: translateX(26px);
                  -ms-transform: translateX(26px);
                  transform: translateX(26px);
                }
              }
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: -2px;
              background: var(--gradient-bg-1);
              -webkit-transition: all 0.4s;
              transition: all 0.4s;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }
            .label {
              position: absolute;
              right: -38px;
              top: 3px;
              font-size: 1.2rem;
              font-weight: 300;
              color: var(--link);
            }
          }
          .fields {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            .field {
              display: flex;
              position: relative;
              flex-direction: column;
              width: 320px;
              height: 65px;
              @media only screen and (max-width: 1120px) {
                flex: 1;
              }
              @media only screen and (max-width: 960px) {
                width: 100%;
                height: 64px;
                flex: inherit;
              }
            }
          }
        }
      }
    }
    .footer {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      margin-top: auto;
      justify-content: space-between;
    }
  }
}
