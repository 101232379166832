.root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  .input {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 12px;
    font-size: 1.125rem;
    padding: 20px 15px 5px 15px;
    line-height: 2.5rem;
    outline: none;
    display: flex;
    flex: 1 1;
    z-index: 1;
    position: relative;
    z-index: 3;
    cursor: pointer;
    color: var(--text-color-1);
    &.expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    // &:disabled {
    //   color: var(--text-color-1);
    // }

    // &:focus {
    //   background-color: var(--main-bg);
    //   box-shadow: inset 0 0 2px var(--text-color-1);
    // }

    img {
      position: absolute;
      width: 40px;
      height: auto;
      right: 10px;
      top: 14px;
    }
  }
  .calendar {
    cursor: default;
    position: absolute;
    width: 100%;
    min-width: 200px;
    min-height: 150px;
    background-color: var(--field-bg);
    bottom: 0;
    top: calc(100% - 15px);
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 5;
    .header {
      background-color: var(--field-bg);
      display: flex;
      margin: 15px 25px;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      .yearSelector,
      .monthSelector {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        justify-content: center;
        flex: 1;
        span {
          appearance: none;
          user-select: none;
          outline: none !important;
          display: flex;
          position: relative;
          cursor: pointer;
          padding: 0;
          border: none;
          transition: all 0.25s ease-out;

          // width: 20px;
          // height: 20px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;

          &.leftArrow {
            border-right: 14px solid var(--accent-color-1);
            // left: 1.5rem;
            &:hover {
              border-right-color: var(--accent-color-2);
            }
          }
          &.rightArrow {
            border-left: 14px solid var(--accent-color-1);
            // left: 1.5rem;
            &:hover {
              border-left-color: var(--accent-color-2);
            }
          }
        }
        div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      background-color: var(--field-bg);
      padding-bottom: 15px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin: 3px 15px;
        background-color: var(--field-bg);
        & > div {
          font-size: 0.75rem;
          font-weight: 400;
          color: var(--text-color-1);
          text-align: center;
          // border-left: 1px solid red;
          // border-right: 0.2px solid red;
        }
      }
      .dates {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin: 0 15px;
        background-color: var(--field-bg);
        & > div {
          font-size: 0.95rem;
          font-weight: 500;
          color: var(--link);
          text-align: center;
          grid-template-rows: 40px;
          // border: 1px solid red;
          // border: 0.2px solid red;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          // transition: all 0.2s;
          cursor: pointer;
          padding-top: 3px;
          border-radius: 3px;
          &:not(.disabled):hover {
            background-color: var(--accent-color-1);
          }
          &.selected {
            background-color: var(--accent-color-1);
            color: rgb(255, 255, 255);
          }
          &.today {
            background-color: #2a3749;
            color: rgb(255, 255, 255);
          }
          &.disabled {
            font-weight: 400;
            color: var(--text-color-1);
            opacity: 0.7;
            cursor: default;
            &.selected {
              background-color: transparent;
              // color: var(--text-color-1);
            }
          }
        }
      }
    }
  }
  .label {
    cursor: text;
    position: absolute;
    left: 15px;
    top: 20%;
    background: transparent;
    color: var(--text-color-1);
    font-size: 0.75rem;
    transition: 0.3s;
    z-index: 4;
  }

  .error {
    padding: 0;
    margin: 0;
    height: 12px;
    padding: 2px 0;
    bottom: -14px;
    color: rgb(222, 104, 104);
    font-weight: 400;
    font-size: 0.775rem;
    text-transform: capitalize;
  }
}
