.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
        input {
          @media only screen and (max-width: 1600px) {
            min-width: 460px;
          }
          //   @media only screen and (max-width: 1164px) {
          //     min-width: 380px;
          //   }
          @media only screen and (max-width: 1080px) {
            width: 100%;
          }
        }
        // button {
        //   @media only screen and (max-width: 1600px) {
        //     margin-left: auto;
        //   }
        //   @media only screen and (max-width: 980px) {
        //     width: 100%;
        //   }
        // }
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        min-height: 400px;
        width: 100%;
        .add {
          // opacity: 0.5;
          width: 25%;
          cursor: pointer;
          transition: opacity 0.2s;
          &:hover {
            opacity: 0.7;
          }
          @media only screen and (max-width: 1600px) {
            width: 33.3%;
          }
          @media only screen and (max-width: 1260px) {
            width: 50%;
          }
          @media only screen and (max-width: 980px) {
            width: 100%;
          }
          .body {
            margin: 10px;
            height: 200px;

            border: 1px dashed var(--text-color-1);
            border-radius: 12px;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 65px;
              height: 65px;
              border: 4px solid var(--text-color-1);
              border-radius: 50%;
              position: relative;
              &:before {
                content: "";
                display: block;
                width: 4px;
                height: 26px;
                position: absolute;
                top: calc(50% - 13px);
                left: calc(50% - 2px);
                background: var(--text-color-1);
              }
              &:after {
                content: "";
                display: block;
                height: 4px;
                width: 26px;
                position: absolute;
                left: calc(50% - 13px);
                top: calc(50% - 2px);
                background: var(--text-color-1);
              }
            }
            .label {
              color: var(--text-color-1);
            }
          }
        }
      }
    }
  }
}
