.root {
  width: 25%;
  @media only screen and (max-width: 1600px) {
    width: 33.3%;
  }
  @media only screen and (max-width: 1260px) {
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    width: 100%;
  }

  .wrapper {
    height: 400px;
    background-color: var(--secondary-bg);
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px;
    border: 1px solid var(--secondary-bg);

    .avatar {
      height: 150px;
      margin: 10px;
      position: relative;
      transition: all 0.2s;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }
    }

    .subtitle {
      color: var(--text-color-1);
      font-size: 0.875rem;
      font-weight: 300;
      margin: 10px 10px 2px 10px;
    }
    .title {
      color: #fff;
      font-weight: 700;
      letter-spacing: -0.33px;
      margin: 0 20px 0 20px;
      font-size: 1.2rem;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 43px;
      line-height: 1.3rem;
      overflow: hidden;
    }
    .description {
      color: var(--text-color-1);
      font-size: 1rem;
      font-weight: 300;
      height: 122px;
      letter-spacing: -0.32px;
      line-height: 1.3rem;
      overflow: hidden;
      // padding: 5px 0;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      margin: 10px 15px;
    }

    .actions {
      display: none;
      margin-top: auto;
      gap: 2px;
      border-radius: 3px;
      .button {
        background: var(--gradient-bg-1);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 14px 0;
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
        cursor: pointer;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    &:hover {
      .avatar {
        height: 105px;
      }
      .actions {
        display: block;
      }
    }
  }
}
