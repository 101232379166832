.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;

          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
        button {
          @media only screen and (max-width: 1600px) {
            margin-left: auto;
          }
          @media only screen and (max-width: 980px) {
            width: 100%;
          }
        }
      }
      .contents {
        // display: flex;
        // flex-wrap: wrap;
        // min-height: 400px;
        // flex-direction: column;
        // width: 100%;

        margin: 0 auto;
        display: grid;
        gap: 1rem;

        @media (min-width: 960px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1140px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 1500px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
