.root {
  display: flex;

  background: var(--field-bg);
  border-radius: 12px;
  .wrapper {
    display: flex;
    // flex-wrap: wrap;
    position: relative;
    flex: 1;
    flex-direction: column;
    .header {
      display: flex;
      flex-direction: row;
      margin: 5px 15px;
      align-items: center;
      gap: 20px;
      @media only screen and (max-width: 1220px) {
        flex-direction: column;
        margin-right: 50px;
        gap: 0;
        .headRow {
          display: flex;
          flex: 1;
          width: 100%;
        }
        span {
          width: auto;
          &.icon {
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
      .headRow {
        display: flex;
        align-items: center;
        gap: 24px;
        &:first-child {
          flex: 1;
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 0;
        color: var(--link);
        font-size: 0.9rem;
        letter-spacing: -0.28px;
        &.caseId {
          width: 40px;
        }
        &.user {
          display: flex;
          flex-direction: row;
          gap: 15px;
          width: 220px;
          img {
            width: 41px;
            height: 41px;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        &.subject {
          flex: 1 1;
          height: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-height: 1rem;
          padding-top: 0px;
          padding-bottom: 0;
        }
        &.icon {
          margin-left: auto;
          cursor: pointer;
        }

        &.status {
          width: 85px;
          font-size: 0.67rem;
          color: #cc33ff;
          background: #cc33ff32;
          border-radius: 20px;
          padding: 5px;
          position: relative;
          cursor: pointer;
          .icon {
            width: 5px;
            height: 5px;
            top: 0;
            right: 7px;
            font-size: 0.67rem;
            position: absolute;
            transform: rotate(0deg);
          }

          &.open {
            color: #2b8e87;
            background: #2b8e872b;
          }
          &.close {
            color: #fc5b0c;
            background: #fc5c0c2a;
          }
          .options {
            position: absolute;
            width: 100%;
            top: 22px;
            left: 0;
            background: var(--main-bg);
            border: 1px solid var(--field-bg);
            z-index: 5;
            & > * {
              padding: 6px 5px;
              color: var(--link);
              &:hover {
                color: grey;
              }
            }
          }
        }
        &.priority {
          width: 80px;
          color: #7a8798;
          font-size: 0.8rem;
          position: relative;
          cursor: pointer;
          &.medium {
            color: #2b8e87;
            // .icon {
            //   color: #2b8e87;
            // }
          }
          &.high {
            color: #cc33ff;
            // .icon {
            //   color: #cc33ff;
            // }
          }
          &.urgent {
            color: #fc5b0c;
            // .icon {
            //   color: #fc5b0c;
            // }
          }

          .icon {
            margin-left: 6px;
            width: 5px;
            height: 5px;
            padding: 0;
            font-size: 0.67rem;
            transform: rotate(0deg);
            color: white;
          }
          .options {
            position: absolute;
            width: 100%;
            top: 22px;
            left: 0;
            background: var(--main-bg);
            border: 1px solid var(--field-bg);
            z-index: 5;
            font-size: 0.67rem;
            & > * {
              padding: 6px 5px;
              color: var(--link);
              &:hover {
                color: grey;
              }
            }
          }
        }
        &.department {
          width: 90px;
          font-size: 0.8rem;
          position: relative;
          cursor: pointer;
          .icon {
            margin-left: 6px;
            width: 5px;
            height: 5px;
            padding: 0;
            font-size: 0.67rem;
            transform: rotate(0deg);
            color: white;
          }
          .options {
            position: absolute;
            width: 100%;
            top: 22px;
            left: 0;
            background: var(--main-bg);
            border: 1px solid var(--field-bg);
            z-index: 5;
            font-size: 0.67rem;
            & > * {
              padding: 6px 5px;
              color: var(--link);
              &:hover {
                color: grey;
              }
            }
          }
        }
        &.date {
          width: 80px;
          font-size: 0.8rem;
        }
      }

      .icon {
        transform: rotate(-90deg);
        width: 14px;
        opacity: 0.3;
        transition: transform 0.15s;
      }

      &.expanded {
        .icon {
          transform: rotate(90deg);
        }
      }
    }
    .body {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      flex-direction: column;
      .message {
        margin: 15px;
        display: flex;
        gap: 25px;
        font-size: 0.95rem;
        word-break: break-all;
        .left {
          img {
            width: 41px;
            height: 41px;
            border-radius: 5px;
            object-fit: cover;
          }
        }

        .right {
          .date {
            font-weight: 300;
            font-size: 0.75rem;
            color: var(--primary);
          }
          .message {
            padding: 10px 0;
            margin: 0;
            word-break: break-all;
          }
        }
      }
      .replies {
        margin: 15px;

        .reply {
          display: flex;
          gap: 25px;
          .left {
            img {
              width: 41px;
              height: 41px;
              border-radius: 5px;
              object-fit: cover;
            }
          }

          .right {
            .date {
              font-weight: 300;
              font-size: 0.75rem;
              color: var(--primary);
            }
            .message {
              padding: 10px 0;
              margin: 0;
              opacity: 0.7;
            }
          }
        }
      }
      .messageComposer {
        display: flex;
        background: #1f242b;
        //   margin: 10px 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 25px 35px;
        form {
          display: flex;
          flex: 1;
          gap: 20px;
          input {
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: var(--main-bg);
            }
            flex: 1;
            line-height: 20px;
            padding: 8px 0 8px 8px;
            max-width: 100%;
            min-width: 0px;
            width: 100%;
            border-radius: 7px;
            border: none;
            background: var(--link);
            color: var(--main-bg);
          }
          button {
            background: var(--gradient-bg-1);
            border: none;
            outline: none;
            border-radius: 6px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:disabled {
              opacity: 0.5;
            }

            cursor: pointer;
            svg {
              width: 15px;
              path {
              }
              fill: none;
            }
          }
        }
      }
    }
  }
}
