.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }

        button {
          margin-left: auto;
          @media only screen and (max-width: 1600px) {
            margin-left: auto;
          }
          @media only screen and (max-width: 980px) {
            width: 100%;
          }
        }
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        min-height: 400px;
        width: 100%;
        flex-direction: column;
        .tabs {
          margin: 10px 10px 50px 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
          border-bottom: 1px solid #303030;

          .tab {
            font-size: 1.2rem;
            font-weight: bold;
            padding: 15px 0;
            letter-spacing: -0.3px;

            color: #fefefe;
            cursor: pointer;
            position: relative;
            transition: color 0.2s;

            &.active {
              color: var(--accent-color-1);
              &::after {
                content: "";
                width: 100%;
                bottom: -2px;
                left: 0;
                height: 2px;
                background: var(--accent-color-1);
                position: absolute;
              }
            }
            &:hover {
              color: var(--accent-color-1);
              // border-bottom: 2px solid var(--accent-color-1);
            }
            .questionCount {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              right: -22px;
              background: var(--gradient-bg-1);
              padding: 5px;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              font-size: 0.675rem;
              font-weight: 700;
              color: #f2ffff;
            }
          }
        }

        .form {
          display: flex;
          flex-direction: column;
          flex: 1;
          form {
            display: flex;
            flex: 1;
            flex-direction: column;

            margin: 10px;
            gap: 45px;
            @media only screen and (max-width: 960px) {
              gap: 20px;
            }
            .row {
              display: flex;
              gap: 15px;
              flex-wrap: wrap;
              flex-direction: column;
              margin-top: 20px;
              @media only screen and (max-width: 960px) {
                flex-direction: column;
                gap: 20px;
              }
              .title {
                color: var(--text-color-1);
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.0625rem;
                margin-bottom: 5px;
              }
              .fields {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-wrap: wrap;
                .field {
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  //   //   width: 320px;
                  //   height: 65px;
                  @media only screen and (max-width: 1120px) {
                    flex: 1;
                  }
                  @media only screen and (max-width: 960px) {
                    width: 100%;
                    // height: 64px;
                    flex: inherit;
                  }
                }
              }
            }
          }

          .terms {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            flex-direction: column;

            @media only screen and (max-width: 960px) {
              flex-direction: column;
              gap: 20px;
            }
            .title {
              color: var(--text-color-1);
              font-size: 1.5rem;
              font-weight: 400;
              line-height: 1.0625rem;
              margin-bottom: 5px;
              margin: 10px;
            }

            .termsContainer {
              display: flex;
              flex-wrap: wrap;
              .add {
                // opacity: 0.5;
                width: 25%;
                cursor: pointer;
                transition: opacity 0.2s;
                &:hover {
                  opacity: 0.7;
                }
                @media only screen and (max-width: 1600px) {
                  width: 33.3%;
                }
                @media only screen and (max-width: 1260px) {
                  width: 50%;
                }
                @media only screen and (max-width: 980px) {
                  width: 100%;
                }
                .body {
                  margin: 10px;
                  height: 400px;

                  border: 1px dashed var(--text-color-1);
                  border-radius: 12px;
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 15px;
                  @media only screen and (max-width: 980px) {
                    height: 200px;
                  }
                  .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 5rem;
                    padding: 30px;
                    width: 20px;
                    height: 20px;
                    color: var(--text-color-1);
                    border: 5px solid var(--text-color-1);
                    border-radius: 50%;
                  }
                  .label {
                    color: var(--text-color-1);
                  }
                }
              }
            }
          }

          .actions {
            display: flex;
            margin: 100px 10px;

            gap: 20px;
            justify-content: flex-end;
            .delete {
              display: flex;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              font-size: 1rem;
              letter-spacing: -0.16px;
              color: #fff;
              border: none;
              box-shadow: inset 0 0 2px var(--text-color-1);

              gap: 5px;
              background: transparent;
              cursor: pointer;
              &:hover {
                background: #262b34;
              }
            }
            .save {
              display: flex;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              font-size: 1rem;
              letter-spacing: -0.16px;
              color: #fff;
              border: none;
              box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
              gap: 5px;
              background: var(--gradient-bg-1);
              cursor: pointer;
              &:hover {
                background: var(--accent-color-1);
              }
            }
            & > *:first-child {
              margin-right: auto;
            }
            @media only screen and (max-width: 996px) {
              & > * {
                width: 50% !important;
              }
            }
            @media only screen and (max-width: 960px) {
              margin-top: 60px;
            }

            @media only screen and (max-width: 640px) {
              // margin-top: 50px;
              flex-direction: column;
              gap: 15px;
              & > * {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
