.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }

        button {
          margin-left: auto;
          @media only screen and (max-width: 1600px) {
            margin-left: auto;
          }
          @media only screen and (max-width: 980px) {
            width: 100%;
          }
        }
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        min-height: 400px;
        width: 100%;
        .form {
          display: flex;
          flex-direction: column;
          flex: 1;
          form {
            display: flex;
            flex: 1;
            flex-direction: column;

            margin: 10px;

            @media only screen and (max-width: 960px) {
              gap: 20px;
            }
            .row {
              display: flex;
              gap: 15px;
              flex-wrap: wrap;
              flex-direction: column;
              margin-top: 20px;
              &:not(:first-child) {
                margin-top: 0;
              }
              @media only screen and (max-width: 960px) {
                flex-direction: column;
                gap: 20px;
              }
              .title {
                color: var(--text-color-1);
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.0625rem;
                margin-bottom: 5px;
              }
              .fields {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-wrap: wrap;
                .field {
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  .videoUploader {
                    border: 1px dashed #748091;
                    padding: 15px;
                    border-radius: 12px;
                    position: relative;
                    height: 100px;
                    label {
                      position: absolute;
                      top: 0%;
                      left: 0;
                      // background: red;
                      padding-left: 150px;
                      width: calc(100% - 150px);
                      height: 100%;
                      display: flex;
                      justify-content: flex-start;

                      align-items: center;
                      border-radius: 12px;
                      color: #909295;
                      letter-spacing: -0.3px;
                      font-size: 1rem;

                      &::before {
                        content: "\2191";
                        cursor: pointer;
                        position: absolute;
                        top: calc(50% - 40px);
                        left: 40px;
                        width: 80px;
                        height: 80px;
                        background: #0b0c0f;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 3.5rem;
                        color: #5b5b5b;
                      }
                    }
                    input {
                      display: none;
                    }
                  }
                  .error {
                    padding: 0;
                    margin: 10px 0;
                    height: 12px;
                    padding: 2px 0;
                    bottom: -14px;
                    color: var(--error-color);
                    font-weight: 400;
                    font-size: 0.775rem;
                    text-transform: capitalize;
                  }
                  @media only screen and (max-width: 1120px) {
                    flex: 1;
                  }
                  @media only screen and (max-width: 960px) {
                    width: 100%;
                    // height: 64px;
                    flex: inherit;
                  }
                }
              }
            }
          }

          .videoList {
            display: flex;
            flex-direction: column;
            gap: 60px;
            margin: 35px 15px;
            .heading {
              color: var(--text-color-1);
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.0625rem;
              margin-bottom: 5px;
            }
            .body {
              display: flex;
              flex-wrap: wrap;
              gap: 40px;

              @media only screen and (max-width: 1120px) {
                flex-direction: column;
              }
              .left {
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  background: white;
                  opacity: 0.7;
                  border-radius: 50%;
                  top: calc(50% - 25px);
                  left: calc(50% - 25px);
                  z-index: 1;
                }
                &:after {
                  content: "";
                  position: absolute;
                  width: 0px;
                  height: 0px;
                  border-style: solid;
                  border-width: 0 10px 24px 10px;
                  border-color: transparent transparent #cc33ffc6 transparent;
                  transform: rotate(90deg);
                  top: calc(50% - 11px);
                  left: calc(50% - 7px);
                  z-index: 2;
                }
                .delete {
                  cursor: pointer;
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  top: -15px;
                  right: -15px;
                  z-index: 4;
                  background: var(--gradient-bg-1);
                  img {
                    width: 14px;
                    // height: 12px;
                  }
                }
                video {
                  position: relative;
                  border-radius: 8px;
                  width: 480px;
                  height: 240px;
                  object-fit: cover;
                  @media only screen and (max-width: 1440px) {
                    height: 240px;
                    width: 300px;
                  }
                  @media only screen and (max-width: 1120px) {
                    width: 100%;
                  }
                }
              }
              .right {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding-bottom: 5px;
                @media only screen and (max-width: 1120px) {
                  gap: 25px;
                }

                .videoName {
                  height: 65px;
                  display: flex;
                }
                .bgImg {
                  margin-top: auto;
                  border: 1px dashed #748091;
                  padding: 15px;
                  border-radius: 12px;
                  position: relative;
                  height: 100px;

                  .label {
                    color: #909295;
                    letter-spacing: -0.3px;
                    font-size: 0.8rem;
                    position: absolute;
                    top: -20px;
                  }
                  label {
                    position: absolute;
                    top: 0%;
                    left: 0;
                    // background: red;
                    padding-left: 150px;
                    width: calc(100% - 150px);
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;

                    align-items: center;
                    border-radius: 12px;
                    color: #909295;
                    letter-spacing: -0.3px;
                    font-size: 1rem;

                    &::before {
                      content: "\2191";
                      cursor: pointer;
                      position: absolute;
                      top: calc(50% - 40px);
                      left: 40px;
                      width: 80px;
                      height: 80px;
                      background: #0b0c0f;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 3.5rem;
                      color: #5b5b5b;
                    }
                  }
                  input {
                    display: none;
                  }

                  img {
                    height: 100%;
                    width: 226px;
                    object-fit: cover;
                    float: right;
                  }
                  .thumbnailName {
                    color: #909295;
                    letter-spacing: -0.3px;
                    font-size: 0.8rem;
                    position: absolute;
                    bottom: -25px;
                  }
                }
              }
            }
          }

          .actions {
            display: flex;
            margin: 100px 10px;

            gap: 20px;
            justify-content: flex-end;
            .save {
              display: flex;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              font-size: 1rem;
              letter-spacing: -0.16px;
              color: #fff;
              border: none;
              box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
              gap: 5px;
              background: var(--gradient-bg-1);
              cursor: pointer;
              &:hover {
                background: var(--accent-color-1);
              }
            }
            & > *:first-child {
              margin-right: auto;
            }
            @media only screen and (max-width: 996px) {
              & > * {
                width: 50% !important;
              }
            }
            @media only screen and (max-width: 960px) {
              margin-top: 60px;
            }

            @media only screen and (max-width: 640px) {
              // margin-top: 50px;
              flex-direction: column;
              gap: 15px;
              & > * {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
