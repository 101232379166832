.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(27, 25, 25, 0.5);
  -webkit-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  .wrapper {
    padding: 40px;
    min-height: 130px;
    width: 360px;
    background: var(--field-bg);
    justify-content: flex-start;
    align-items: center;

    border-radius: 15px;

    .body {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      .label {
        color: var(--text-color-1);

        display: flex;

        width: 100%;
      }

      .select {
        position: relative;
        cursor: pointer;
        background-color: var(--dialog-bg);
        color: var(--link);
        border: 2px px solid var(--dialog-bg);
        border-radius: 12px;
        font-size: 1.125rem;
        height: 65px;
        outline: none;
        display: flex;
        z-index: 1;
        position: relative;
        align-items: center;
        width: 100%;
        .value {
          margin: 10px 40px 10px 15px;
          display: flex;
          flex: 1;
        }
        svg {
          position: absolute;
          right: 15px;
          top: 24px;
          transform: rotate(-90deg);
          opacity: 0.6;
          transition: all 0.25s;
          &.expanded {
            transform: rotate(90deg);
          }
        }
      }
      .options {
        display: none;
        position: absolute;
        width: 100%;
        top: 90px;
        z-index: 5;
        background-color: var(--dialog-bg);
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        max-height: 150px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
          margin-right: 2px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          // box-shadow: inset 0 0 5px grey;
          border-radius: 8px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #48515d;

          // border-right: 6px transparent solid;
          background-clip: padding-box;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        &.show {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        .option {
          display: block;
          padding: 0;
          margin: 0;
          label {
            padding: 10px 15px;
            display: flex;
            align-items: center;
            color: var(--text-color-1);
            font-size: 0.95rem;
            cursor: pointer;
            transition: all 0.15s;
            &:hover {
              background: var(--field-bg);
            }
          }
          input {
            // visibility: hidden;
            display: none;
          }
        }
      }

      .actions {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 15px;
        margin-top: 25px;
        button {
          display: flex;
          // flex: 1;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          min-height: 35px;
          width: 170px;
          border: none;
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
          gap: 5px;
          background: var(--gradient-bg-1);
          cursor: pointer;
          font-weight: 700;
          font-size: 1rem;
          letter-spacing: -0.18px;
          color: #fff;
          &:hover {
            background: var(--accent-color-1);
          }

          &:first-child {
            background: transparent;
            box-shadow: inset 0 0 2px var(--text-color-1);
            &:hover {
              background: #262b34;
            }
          }
        }
      }
    }
  }

  // .error {
  //   padding: 0;
  //   margin: 0;
  //   height: 12px;
  //   padding: 2px 0;
  //   bottom: -14px;
  //   color: var(--error-color);
  //   font-weight: 400;
  //   font-size: 0.775rem;
  //   text-transform: capitalize;
  // }
}
