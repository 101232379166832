.root {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .category {
        width: calc(25% - 15px);
        @media only screen and (max-width: 1600px) {
          width: calc(33.3% - 15px);
        }
        @media only screen and (max-width: 1260px) {
          width: calc(50% - 15px);
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
        }
        .body {
          // margin: 10px;
          height: 140px;
          background-color: var(--secondary-bg);
          border-radius: 12px;
          display: flex;
          flex: 1;
          flex-direction: column;
          position: relative;
          &.hovered {
            opacity: 0.3;
            box-shadow: inset 0 0 3px var(--text-color-1);
          }
          input {
            cursor: pointer;
            -webkit-appearance: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: -5px;

            border-radius: 12px;

            &::before {
              content: "";
              position: absolute;
              height: 1.4em;
              width: 1.4em;
              top: 2.75em;
              left: 15px;
              border-radius: 50%;
              background: var(--field-bg);
              border: 1px solid var(--secondary-bg);
              outline: none;
            }
            &:checked {
              &::after {
                content: "";
                position: absolute;
                height: 0.8em;
                width: 0.8em;
                top: 3.13em;
                left: 20px;
                border-radius: 50%;
                background: var(--accent-color-2);
                outline: none;
              }
            }

            // &.active {
            //   &::after {
            //     content: "";
            //     position: absolute;
            //     height: 0.8em;
            //     width: 0.8em;
            //     top: 3.13em;
            //     left: 20px;
            //     border-radius: 50%;
            //     background: var(--accent-color-2);
            //     outline: none;
            //   }
            // }
          }
          label {
            color: var(--link);
            margin-left: 45px;
            margin-right: 12px;
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
            letter-spacing: -0.33px;
            margin-top: 37px;
            word-break: break-word;
          }

          .actions {
            display: flex;
            // gap: 3px;
            margin-top: auto;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            position: relative;
            background: var(--gradient-bg-1);
            .button {
              background: pink;
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
              background: transparent;
              padding: 10px 0;
              font-size: 1rem;
              font-weight: 700;
              color: #f2ffff;
              cursor: pointer;
              &:hover {
                color: white;
              }
              &:first-child {
                border-bottom-left-radius: 12px;
                border-right: 1px solid var(--secondary-bg);
              }
              &:last-child {
                border-bottom-right-radius: 12px;
                border-left: 1px solid var(--secondary-bg);
              }
            }
          }
        }
      }
      .add {
        // opacity: 0.5;

        cursor: pointer;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.7;
        }
        width: calc(25% - 15px);
        @media only screen and (max-width: 1600px) {
          width: calc(33.3% - 15px);
        }
        @media only screen and (max-width: 1260px) {
          width: calc(50% - 15px);
        }
        @media only screen and (max-width: 980px) {
          width: 100%;
        }
        .body {
          height: 138px;

          border: 1px dashed var(--text-color-1);
          border-radius: 12px;
          display: flex;
          flex: 1;

          justify-content: center;
          align-items: center;
          gap: 15px;
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 65px;
            height: 65px;
            border: 4px solid var(--text-color-1);
            border-radius: 50%;
            position: relative;
            &:before {
              content: "";
              display: block;
              width: 4px;
              height: 26px;
              position: absolute;
              top: calc(50% - 13px);
              left: calc(50% - 2px);
              background: var(--text-color-1);
            }
            &:after {
              content: "";
              display: block;
              height: 4px;
              width: 26px;
              position: absolute;
              left: calc(50% - 13px);
              top: calc(50% - 2px);
              background: var(--text-color-1);
            }
          }
          .label {
            color: var(--text-color-1);
          }
        }
      }
    }
  }
}
