.root {
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    .field {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 218px;
      height: 66px;
      padding: 15px;
      background: var(--field-bg);
      border: 1px solid var(--field-bg);
      border-radius: 12px;
      flex: 1;

      @media only screen and (max-width: 1024px) {
        flex: 1 1 34%;
      }
      &.disabled {
        opacity: 0.5;
      }
      &.active {
        background: #323944;
        border: 1px solid #663ca0;
      }
      label {
        color: var(--link);
        margin-left: 35px;
      }
      input {
        cursor: pointer;
        -webkit-appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: -3px;
        left: -5px;
        border-radius: 12px;

        &::before {
          content: "";
          position: absolute;
          height: 1.4em;
          width: 1.4em;
          top: 2.75em;
          left: 15px;
          border-radius: 50%;
          background: var(--field-bg);
          border: 1px solid var(--secondary-bg);
          outline: none;
        }
        &:checked {
          &::after {
            content: "";
            position: absolute;
            height: 0.8em;
            width: 0.8em;
            top: 3.13em;
            left: 20px;
            border-radius: 50%;
            background: var(--accent-color-2);
            outline: none;
          }
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            height: 0.8em;
            width: 0.8em;
            top: 3.13em;
            left: 20px;
            border-radius: 50%;
            background: var(--accent-color-2);
            outline: none;
          }
        }
      }
    }
  }
}
