.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(27, 25, 25, 0.5);
  -webkit-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  .wrapper {
    padding: 40px;
    min-height: 130px;
    // max-width: 320px;
    background: var(--field-bg);
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    gap: 15px;
    position: relative;
    .title {
      display: flex;
      flex: 1 1 100%;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: auto;
      margin-bottom: 20px;
      font-size: 1.6rem;
      //   font-weight: bold;
      color: var(--link);
    }
    input {
      background: var(--dialog-bg);
    }
    .row {
      display: flex;
      width: 100%;
      gap: 15px;
    }
    .actions {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 15px;
      button {
        display: flex;
        // flex: 1;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        min-height: 35px;
        width: 170px;
        border: none;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
        gap: 5px;
        background: var(--gradient-bg-1);
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: -0.18px;
        color: #fff;
        &:hover {
          background: var(--accent-color-1);
        }

        &:first-child {
          background: transparent;
          box-shadow: inset 0 0 2px var(--text-color-1);
          &:hover {
            background: #262b34;
          }
        }
      }
    }
  }
}
