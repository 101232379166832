.root {
  width: 25%;
  @media only screen and (max-width: 1600px) {
    width: 33.3%;
  }
  @media only screen and (max-width: 1260px) {
    width: 50%;
  }
  @media only screen and (max-width: 980px) {
    width: 100%;
  }
  .wrapper {
    margin: 10px;
    height: 280px;
    background-color: var(--secondary-bg);
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    &.isDisabled {
      opacity: 0.5;
    }
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 15px;
      .avatar {
        width: 44px;
        height: 44px;
        margin-right: 5px;
        img {
          width: 44px !important;
          height: 44px !important;
          object-fit: cover;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
        .name {
          font-size: 0.9rem;
          line-height: 1.4rem;
          font-weight: 900;
          color: #f2ffff;
          padding: 3px 0;
        }
        .role {
          font-size: 0.6875rem;
          font-weight: 500;
          color: var(--text-color-1);
          padding: 3px 0;
          text-transform: capitalize !important;
        }
      }
      .muted {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .mail {
      margin: 8px 15px;
      .title {
        font-size: 0.6875rem;
        font-weight: 500;
        color: var(--text-color-1);
        padding: 3px 0;
        text-transform: capitalize !important;
      }
      .value {
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
      }
    }
    .phone {
      margin: 8px 15px;
      .title {
        font-size: 0.6875rem;
        font-weight: 500;
        color: var(--text-color-1);
        padding: 3px 0;
        text-transform: capitalize !important;
      }
      .value {
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
      }
    }
    .date {
      margin: 15px;
      font-size: 0.6875rem;
      font-weight: 500;
      color: var(--text-color-1);
      text-transform: capitalize !important;
      margin-top: auto;
    }
    .actions {
      display: none;
      background: var(--gradient-bg-1);
      gap: 2px;
      border-radius: 3px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .button {
        background: transparent;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 10px 0;
        font-size: 0.875rem;
        font-weight: 700;
        color: #f2ffff;
        cursor: pointer;
        &.disabled {
          color: #00000088;
        }
        &:nth-child(2) {
          border-left: 2px solid var(--secondary-bg);
          border-right: 2px solid var(--secondary-bg);
        }
        // &:last-child {
        //   border-bottom-right-radius: 15px;
        // }
      }
    }
    &:hover {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .actions {
        display: flex;
      }
    }
  }
}
