.root {
  // margin: 10px;
  display: flex;
  flex-direction: column;
  background: var(--secondary-bg);
  // align-items: stretch;
  border-radius: 12px;
  box-shadow: 0 11px 14px #0000001a;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 12px;
    padding: 15px;
    .avatar {
      // width: 351px;
      height: 160px;
      overflow: hidden;
      // @media only screen and (max-width: 1600px) {
      //   height: 220px;
      // }
      // @media only screen and (max-width: 1400px) {
      //   height: 240px;
      // }
      // @media only screen and (max-width: 1200px) {
      //   height: 194px;
      //   width: 100%;
      //   margin-bottom: 15px;
      // }
      img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // @media only screen and (max-width: 1200px) {
        //   border-radius: 0;
        // }
      }
    }
    .details {
      // padding: 0 25px;
      flex: 1;
      position: relative;
      //   cursor: pointer;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      h1 {
        margin: 20px 0 10px 0;
        color: white;
        font-weight: bold;
        font-size: 1.625rem;

        letter-spacing: -0.32px;
        line-height: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 62px;
      }
      // h4 {
      //   margin: 0;
      //   color: var(--text-color-1);
      //   font-size: 0.875rem;
      //   font-weight: 300;
      // }
      .description {
        // font-size: 1rem;
        // font-weight: 300;
        // color: var(--text-color-1);
        // padding: 10px 0;
        // letter-spacing: -0.32px;
        // line-height: 1.3rem;
        // min-height: 50px;
        // word-break: break-all;

        color: var(--text-color-1);
        font-size: 1rem;
        font-weight: 300;
        height: 84px;
        letter-spacing: -0.32px;
        line-height: 1.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        // margin: 10px 15px;
      }

      .footer {
        display: flex;
        margin-top: 15px;
        gap: 15px;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (max-width: 1200px) {
          margin-bottom: 15px;
        }
        .stats {
          display: flex;
          align-items: center;
          color: var(--text-color-1);
          gap: 10px;
          font-size: 0.875rem;
          font-weight: 300;
          flex: 1;
          // img {
          //   border-radius: 50%;
          //   width: 24px;
          //   height: 24px;
          // }
          // span {
          //   color: var(--text-color-1);
          // }
        }
        .actions {
          display: flex;
          margin-left: auto;
          align-items: center;
          gap: 10px;
          .lockBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            padding: 10px;
            background: var(--gradient-bg-1);
            color: var(--link-color);
            &.locked {
              background: var(--field-bg);
              color: var(--accent-color-2);
            }
          }

          // font-size: 0.875rem;
          // font-weight: 300;
          // // width: 70px;
          // color: var(--text-color-1);
          // span {
          //   font-weight: 800;
          // }
        }
        // .weeks {
        //   font-size: 0.875rem;
        //   font-weight: 300;
        //   // width: 70px;
        //   color: var(--text-color-1);
        //   span {
        //     font-weight: 800;
        //   }
        // }
      }
    }
    .button {
      cursor: pointer;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      background: var(--gradient-bg-1);
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        top: 0;
      }
    }
  }

  .navigation {
    display: flex;
    margin-top: auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
    background: var(--gradient-bg-1);
    .button {
      background: pink;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      background: transparent;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: 700;
      color: #f2ffff;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
        color: black;
        cursor: not-allowed;
      }
      // &:hover {
      //   color: white;
      // }
      &:first-child {
        border-bottom-left-radius: 12px;
        border-right: 1px solid var(--secondary-bg);
      }
      &:last-child {
        border-bottom-right-radius: 12px;
        border-left: 1px solid var(--secondary-bg);
      }
    }
  }
}
