.root {
  margin: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  label {
    font-size: 0.875rem;
    color: var(--text-color-1);
  }

  .avatar {
    position: relative;
    img {
      width: 80px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
    }
    .delete {
      cursor: pointer;
      border: 2px solid var(--main-bg);
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--accent-color-2);
      top: 0px;
      right: -7px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.6em;
    }
  }
  .fileUpload {
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: 102px;
    margin-left: 30px;
    height: 42px;
    background: var(--gradient-bg-1);
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: -0.16px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: var(--accent-color-1);
    }

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}
