.root {
  display: flex;
  flex-wrap: wrap;
  .video {
    width: 25%;
    @media only screen and (max-width: 1600px) {
      width: 33.3%;
    }
    @media only screen and (max-width: 1260px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
    }
    .body {
      margin: 10px;
      height: 325px;
      background-color: var(--secondary-bg);
      border-radius: 12px;
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;
      &.hovered {
        opacity: 0.3;
        box-shadow: inset 0 0 3px var(--text-color-1);
      }
      .orderHandler {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        color: var(--text-color-1);
        font-size: 0.65rem;
        cursor: grab;
        // margin-bottom: 15px;
        gap: 7px;
        .icon {
          position: relative;
          width: 17px;
          height: 5px;
          &::before {
            content: "...";
            position: absolute;
            top: -4px;
            left: 0;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 0;
          }
          &::after {
            content: "...";
            position: absolute;
            top: 2px;
            left: 0;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 0;
          }
        }
      }
      .thumbnail {
        display: flex;
        height: 168px;
        margin: 15px;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 46px;
          height: 46px;
          background: white;
          opacity: 0.7;
          border-radius: 50%;
          top: calc(50% - 23px);
          left: calc(50% - 23px);
        }
        &:after {
          content: "";
          position: absolute;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 10px 24px 10px;
          border-color: transparent transparent #cc33ffc6 transparent;
          transform: rotate(90deg);
          top: calc(50% - 11px);
          left: calc(50% - 7px);
        }
      }
      .subtitle {
        margin: 0 15px;
        font-size: 0.85rem;
        font-weight: 300;
        color: var(--text-color-1);
        padding: 3px 0;
        text-transform: capitalize !important;
      }
      .title {
        margin: 0 15px 5px 15px;
        font-size: 1rem;
        font-weight: 700;
        color: #f2ffff;
        height: 40px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .footer {
        display: flex;
        // background: red;
        margin: 0 15px 10px 15px;
        padding: 0;
        margin-top: auto;
        input {
          margin-left: auto;
          width: 20px;
          height: 20px;
          position: relative;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #202934;
          }

          &:checked:after {
            content: "\2713";
            position: absolute;
            top: -4px;
            left: 2px;
            color: #cc33ff;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .add {
    // opacity: 0.5;
    width: 25%;
    cursor: pointer;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
    @media only screen and (max-width: 1600px) {
      width: 33.3%;
    }
    @media only screen and (max-width: 1260px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
    }
    .body {
      margin: 10px;
      height: 323px;
      border: 1px dashed var(--text-color-1);
      border-radius: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 15px;

      @media only screen and (max-width: 980px) {
        height: 160px;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        border: 5px solid var(--text-color-1);
        border-radius: 50%;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 4px;
          height: 26px;
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 2px);
          background: var(--text-color-1);
        }
        &:after {
          content: "";
          display: block;
          height: 4px;
          width: 26px;
          position: absolute;
          left: calc(50% - 13px);
          top: calc(50% - 2px);
          background: var(--text-color-1);
        }
      }
      .label {
        color: var(--text-color-1);
      }
    }
  }
}
