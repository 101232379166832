.root {
  background: var(--field-bg);
  margin: 10px 0;
  border-radius: 12px;
  .comment {
    padding: 15px;

    display: flex;
    .left {
      img {
        width: 41px;
        height: 41px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      flex: 1;
      color: white;
      padding: 0 10px;
      .header {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .name {
          font-weight: 700;

          font-size: 1rem;
        }
        .date {
          font-weight: 300;
          font-size: 0.75rem;
          color: var(--primary);
        }
      }
      .body {
        letter-spacing: -0.4px;
        margin-top: 6px;
        line-height: 1.2rem;
        color: white;
        word-break: break-all;
      }
      .footer {
        margin-top: 25px;
        padding: 10px 0;
        display: flex;
        gap: 15px;
        font-size: 0.7rem;

        .reply {
          color: var(--primary);
        }
      }
    }

    .right {
      width: 40px;
      position: relative;
    }
  }
  .replies {
    background: var(--secondary-bg);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px;
    color: white;
    .replyText {
      border-bottom: 1px solid rgba(234, 234, 234, 0.225);
      margin: 0px 25px 0px 50px;
      padding: 15px 0;
      display: flex;
      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 41px;
          height: 41px;
          border-radius: 4px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        color: white;
        padding: 0 10px;

        .header {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          .name {
            font-weight: 700;
            color: var(--accent-color-1);
            font-size: 1rem;
          }
          .date {
            font-weight: 300;
            font-size: 0.75rem;
            color: #a7a5a5;
          }
        }
        .body {
          color: #a7a5a5;
          word-break: break-all;
          &.adminMessage {
            color: white;
            // font-size: 1.1rem;
          }
        }
      }
    }

    .messageComposer {
      display: flex;
      // background: var(--field-bg);
      margin: 10px 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 25px 45px;
      form {
        display: flex;
        flex: 1;
        gap: 20px;
        input {
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: var(--main-bg);
          }
          flex: 1;
          line-height: 20px;
          padding: 8px 0 8px 8px;
          max-width: 100%;
          min-width: 0px;
          width: 100%;
          border-radius: 7px;
          border: none;
          background: #a4a2a3;
          color: var(--main-bg);
        }
        button {
          background: var(--gradient-bg-1);
          border: none;
          outline: none;
          border-radius: 6px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:disabled {
            opacity: 0.5;
          }

          cursor: pointer;
          svg {
            width: 15px;
            path {
            }
            fill: none;
          }
        }
      }
    }
  }
}
