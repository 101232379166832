.root {
  display: flex;
  align-items: stretch;
  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.15s;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 780px) {
      margin-left: 50px;
    }
    &.collapsed {
      margin-left: 50px;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-width: var(--max-container-width);
      width: calc(100% - 40px);
      padding: 0 20px;
      margin: 0 auto;
      // box-shadow: inset 0 0 10px red;
      color: white;

      .header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 25px 10px 50px 10px;
        flex: 0 0 100%;
        justify-content: flex-start;
        align-items: center;
        flex: inherit;
        // width: 100%;
        @media only screen and (max-width: 980px) {
          // flex: 1;
        }
        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-right: 30px;
          @media only screen and (max-width: 1160px) {
            width: 100%;
          }
          .title {
            margin: 0;
            font-size: 2.25rem;
            font-weight: 400;
            letter-spacing: -0.54px;
            color: white;
          }
          .subtitle {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            color: var(--text-color-1);
          }
        }
        input {
          @media only screen and (max-width: 1600px) {
            min-width: 460px;
          }
          //   @media only screen and (max-width: 1164px) {
          //     min-width: 380px;
          //   }
          @media only screen and (max-width: 1080px) {
            width: 100%;
          }
        }
        // button {
        //   @media only screen and (max-width: 1600px) {
        //     margin-left: auto;
        //   }
        //   @media only screen and (max-width: 980px) {
        //     width: 100%;
        //   }
        // }
      }
      .contents {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-height: 400px;
        padding: 10px;
        flex: 1;
        .categories {
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex: 1;
          .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 40px;

            .item {
              width: calc(20% - 32px);
              @media only screen and (max-width: 1600px) {
                width: calc(33.3% - 27px);
              }
              @media only screen and (max-width: 1260px) {
                width: calc(50% - 20px);
              }
              @media only screen and (max-width: 980px) {
                width: 100%;
              }
              &.active {
                .body > .actions {
                  display: flex;
                }
              }
              .body {
                height: 90px;
                background: var(--field-bg);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: center;
                align-items: center;
                @media only screen and (max-width: 980px) {
                  height: 110px;
                }
                &:hover {
                  .actions {
                    display: flex;
                  }
                }
                .label {
                  display: flex;
                  flex: 1 1;
                  align-items: center;
                  justify-content: center;
                  width: calc(100% - 8px);
                  height: 40px;
                  // height: auto;
                  cursor: pointer;
                  text-align: center;
                  padding: 4px;
                }
                .actions {
                  display: none;
                  transition: all 0.15s;
                  gap: 1px;
                  margin-top: auto;
                  width: 100%;
                  height: 27px;
                  justify-content: space-around;
                  @media only screen and (max-width: 980px) {
                    height: 37px;
                  }

                  span {
                    display: flex;
                    flex: 1;
                    background: var(--gradient-bg-1);
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &:first-child {
                      border-bottom-left-radius: 12px;
                    }
                    &:last-child {
                      border-bottom-right-radius: 12px;
                    }
                  }
                }
              }
            }
            .add {
              // opacity: 0.5;

              cursor: pointer;
              transition: opacity 0.2s;

              &:hover {
                opacity: 0.7;
              }
              width: calc(20% - 32px);
              @media only screen and (max-width: 1600px) {
                width: calc(33.3% - 27px);
              }
              @media only screen and (max-width: 1260px) {
                width: calc(50% - 20px);
              }
              @media only screen and (max-width: 980px) {
                width: 100%;
              }
              .body {
                height: 77px;

                border: 1px dashed var(--text-color-1);
                border-radius: 12px;
                display: flex;
                flex: 1;

                justify-content: center;
                align-items: center;
                gap: 15px;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 45px;
                  height: 45px;
                  border: 3px solid var(--text-color-1);
                  border-radius: 50%;
                  position: relative;
                  &:before {
                    content: "";
                    display: block;
                    width: 4px;
                    height: 20px;
                    position: absolute;
                    top: calc(50% - 10px);
                    left: calc(50% - 2px);
                    background: var(--text-color-1);
                  }
                  &:after {
                    content: "";
                    display: block;
                    height: 4px;
                    width: 20px;
                    position: absolute;
                    left: calc(50% - 10px);
                    top: calc(50% - 2px);
                    background: var(--text-color-1);
                  }
                }
                .label {
                  color: var(--text-color-1);
                }
              }
            }
          }
        }
        .tickets {
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex: 1;
          margin-top: 60px;
          margin-bottom: 100px;
          .filterBar {
            display: flex;
            flex-direction: row;
            margin: 5px 15px;
            align-items: center;
            gap: 20px;
            @media only screen and (max-width: 1220px) {
              flex-direction: column;
              margin-right: 50px;
              gap: 0;
              .headRow {
                display: flex;
                flex: 1;
                width: 100%;
                justify-content: space-between;
                &:first-child {
                  display: none;
                }
              }
              span {
                width: auto;
                &.icon {
                  position: absolute;
                  top: 12px;
                  right: 15px;
                }
              }
            }
            .headRow {
              display: flex;
              align-items: center;
              gap: 24px;
              &:first-child {
                flex: 1;
              }
            }
            span {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: var(--text-color-1);

              font-size: 0.77rem;
              font-weight: 400;
              letter-spacing: -0.28px;

              letter-spacing: -0.28px;
              &.caseId {
                width: 40px;
              }
              &.user {
                display: flex;
                flex-direction: row;
                gap: 15px;
                width: 220px;
              }
              &.subject {
                flex: 1 1;
                display: flex;
              }
              &.icon {
                margin-left: auto;
                cursor: pointer;
                width: 14px;
                display: flex;
              }

              &.status {
                width: 85px;
              }
              &.priority {
                width: 80px;
              }
              &.department {
                width: 90px;
              }
              &.date {
                width: 80px;
              }
            }
          }
        }
      }
    }
  }
}
