.root {
  display: flex;
  align-items: stretch;
  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    .picture {
      margin: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;
      label {
        font-size: 0.875rem;
        color: var(--text-color-1);
      }

      .avatar {
        position: relative;
        img {
          width: 80px;
          height: 180px;
          border-radius: 50%;
        }
        .delete {
          cursor: pointer;
          border: 2px solid var(--main-bg);
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--accent-color-2);
          top: 0px;
          right: -7px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 0.6em;
        }
      }
      .fileUpload {
        display: flex;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        width: 102px;
        margin-left: 30px;
        height: 42px;

        background: var(--gradient-bg-1);
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: -0.16px;
        color: #fff;
        &:hover {
          background: var(--accent-color-1);
        }
        cursor: pointer;
      }
    }
    form {
      display: flex;
      flex: 1;
      flex-direction: column;

      margin: 10px;
      gap: 45px;
      @media only screen and (max-width: 960px) {
        gap: 20px;
      }
      .row {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 20px;
        @media only screen and (max-width: 960px) {
          flex-direction: column;
          gap: 20px;
        }
        .title {
          color: var(--text-color-1);
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.0625rem;
          margin-bottom: 5px;
        }
        .fields {
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          .field {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 320px;
            height: 65px;
            @media only screen and (max-width: 1120px) {
              flex: 1;
            }
            @media only screen and (max-width: 960px) {
              width: 100%;
              height: 64px;
              flex: inherit;
            }
          }
        }
        .courseContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .courseItem {
            width: calc(25% - 15px);
            @media only screen and (max-width: 1600px) {
              width: calc(33.3% - 15px);
            }
            @media only screen and (max-width: 1260px) {
              width: calc(50% - 15px);
            }
            @media only screen and (max-width: 980px) {
              width: 100%;
            }
            .wrapper {
              height: 394px;
              background-color: var(--secondary-bg);
              border-radius: 12px;
              display: flex;
              flex: 1;
              flex-direction: column;
              // margin: 10px;
              border: 1px solid var(--secondary-bg);
              .avatar {
                height: 150px;
                margin: 10px;
                position: relative;
                transition: all 0.2s;
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  border-radius: 7px;
                }
              }
              .subtitle {
                color: var(--text-color-1);
                font-size: 0.875rem;
                font-weight: 300;
                margin: 10px 20px 2px 20px;
              }
              .title {
                color: #fff;
                font-weight: 700;
                letter-spacing: -0.33px;
                margin: 10px 10px 20px 20px;
                font-size: 1.2rem;
              }
              .description {
                color: var(--text-color-1);
                font-size: 1rem;
                font-weight: 300;
                height: 98px;
                letter-spacing: -0.32px;
                line-height: 1.28rem;
                overflow: hidden;
                padding: 5px 0;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                margin: 0 20px 20px;
              }
              .classView {
                cursor: pointer;
                color: var(--text-color-1);
                font-size: 1rem;
                font-weight: 300;
                margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                padding: 10px 20px;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                &:hover {
                  color: var(--link);
                }
              }
            }
          }
          .add {
            // opacity: 0.5;
            // width: 25%;
            cursor: pointer;
            transition: opacity 0.2s;
            &:hover {
              opacity: 0.7;
            }
            // @media only screen and (max-width: 1600px) {
            //   width: 33.3%;
            // }
            // @media only screen and (max-width: 1260px) {
            //   width: 50%;
            // }
            // @media only screen and (max-width: 980px) {
            //   width: 100%;
            // }
            width: calc(25% - 15px);
            @media only screen and (max-width: 1600px) {
              width: calc(33.3% - 15px);
            }
            @media only screen and (max-width: 1260px) {
              width: calc(50% - 15px);
            }
            @media only screen and (max-width: 980px) {
              width: 100%;
            }
            .body {
              // margin: 10px;
              height: 394px;

              border: 1px dashed var(--text-color-1);
              border-radius: 12px;
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 15px;
              @media only screen and (max-width: 980px) {
                height: 200px;
              }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75px;
                height: 75px;
                border: 5px solid var(--text-color-1);
                border-radius: 50%;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 4px;
                  height: 26px;
                  position: absolute;
                  top: calc(50% - 13px);
                  left: calc(50% - 2px);
                  background: var(--text-color-1);
                }
                &:after {
                  content: "";
                  display: block;
                  height: 4px;
                  width: 26px;
                  position: absolute;
                  left: calc(50% - 13px);
                  top: calc(50% - 2px);
                  background: var(--text-color-1);
                }
              }
              .label {
                color: var(--text-color-1);
              }
            }
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    margin: 100px 10px 100px 10px;
    // margin-top: auto;
    gap: 20px;
    justify-content: space-between;
    & > *:last-child {
      margin-left: auto;
    }
    @media only screen and (max-width: 996px) {
      & > * {
        width: 50% !important;
      }
    }
    @media only screen and (max-width: 960px) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 640px) {
      // margin-top: 50px;
      flex-direction: column;
      gap: 15px;
      & > * {
        width: 100% !important;
      }
    }
  }
}
