.root {
  display: flex;
  flex-direction: row;
  flex: 0 1;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;

  .select {
    background-color: var(--field-bg);
    color: var(--link);
    border: 2px px solid var(--field-bg);
    border-radius: 8px;
    font-size: 1.125rem;
    height: 50px;
    outline: none;
    display: flex;
    z-index: 1;
    position: relative;
    flex: 1;
    label {
      cursor: text;
      position: absolute;
      left: 15px;
      top: 7px;
      background: transparent;
      color: var(--text-color-1);
      font-size: 0.75rem;
      transition: 0.3s;
      z-index: 2;
      border: none;
      outline: none;
    }
    .value {
      // background-color: red;
      align-items: center;
      margin: 26px 30px 4px 15px;
      border: none;
      outline: none;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 16px;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 12px;
      transform: rotate(-90deg);
      opacity: 0.6;
      transition: all 0.25s;
      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  .options {
    // display: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    top: 46px;
    z-index: 5;
    background-color: var(--field-bg);
    // padding-top: 15px;
    // padding-bottom: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    // max-height: 300px;
    height: 0;
    overflow-y: hidden;
    -webkit-transition: all 0.25s;
    transition: all 0.2s;

    .termTitle {
      font-size: 0.85rem;
      color: var(--text-color-1);
      padding: 5px 15px 0 15px;
      word-break: break-all;
      // margin-bottom: 10px;
      // display: flex;
    }

    &::-webkit-scrollbar {
      width: 4px;
      margin-right: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #48515d;

      // border-right: 6px transparent solid;
      background-clip: padding-box;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &.show {
      // display: flex;
      // flex-direction: column;
      // gap: 5px;
      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      max-height: 300px;
      height: auto;
      overflow-y: scroll;
    }
  }
}
